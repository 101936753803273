<template>
  <div class="skills-container">
    <b-row v-if="isChild">
      <b-col>
        <b-button variant="primary w-100" @click.prevent="rollback">
          <b-icon icon="arrow-left-short"></b-icon>
          <span>Indietro</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <template v-for="(skill, i) in filteredSkills">
        <skill
          :key="skill.id"
          :skill="skill"
          @childClicked="replaceWithChildren(skill)"
          :last="
            i === filteredSkills.length - 1 &&
            (i === 0 || filteredSkills.length % 2 !== 0)
          "
        />
      </template>
    </b-row>
  </div>
</template>

<script>
import { isArray } from "lodash";

import Skill from "./Skill.vue";
//import SkillChildren from "./SkillChildren.vue";

export default {
  name: "Skills",
  components: { Skill /*, SkillChildren*/ },
  props: ["skills"],

  data() {
    return {
      filteredSkills: [],
      isChild: false,
    };
  },

  created() {
    this.filteredSkills = this.skills;
  },

  methods: {
    replaceWithChildren(skill) {
      if (isArray(skill.children)) {
        this.filteredSkills = skill.children;
        this.isChild = true;
      }
    },

    rollback() {
      this.filteredSkills = this.skills;
      this.isChild = false;
    },
  },

  watch: {
    skills() {
      this.filteredSkills = this.skills;
    },
  },
};
</script>

<style lang="scss" scoped>
.row:not(:last-child) {
  padding-bottom: 3px;
}

.col {
  padding-right: 3px;
  padding-left: 3px;
}

.btn {
  display: flex;
  align-items: center;
  font-size: 0.8rem;

  span {
    flex: 1;
  }
}
</style>
