<template>
  <b-col :cols="last ? 12 : 6" :class="[last ? 'last' : '']">
    <b-button
      variant="primary"
      @click.prevent="setNewTagSkill(skill)"
      :class="{ active: isSkillActive }"
      :title="skill.name"
    >
      <span>{{ skill.name }}</span>
      <b-icon icon="arrow-right-short" v-if="!!skill.children"></b-icon>
    </b-button>
  </b-col>
</template>

<script>
import { pick } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Skill",
  props: {
    skill: Object,
    child: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("videotag", {
      selectedSkillIds: "newTagSkillIds",
    }),

    isSkillActive() {
      return (
        this.selectedSkillIds && this.selectedSkillIds.includes(this.skill.id)
      );
    },
  },

  methods: {
    setNewTagSkill(skill) {
      if (skill.children) {
        this.$emit("childClicked", skill);
        return;
      }

      if (this.selectedSkillIds.includes(skill.id)) {
        this.$store.commit("videotag/removeSkill", skill.id);
      } else {
        this.$store.commit("videotag/updateTagField", {
          key: "skill",
          value: pick(skill, ["id", "name"]),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  height: 69px;
  font-weight: 400;
  font-size: 0.8rem;
  white-space: nowrap;
  display: flex;
  align-items: center;

  span {
    flex: 1;
    overflow: hidden;
    /*  text-overflow: ellipsis; */
  }
}

.col,
[class*="col-"] {
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 3px;
}
</style>
