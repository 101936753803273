<template>
  <b-row class="filters-tag-list">
    <b-col v-for="(tag, i) in tags" :key="tag.id" cols="12" lg="6" class="mb-2">
      <tag :tag="tag" :index="i + 1" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import Tag from "./Tag.vue";

export default {
  name: "Tags",
  components: { Tag },

  computed: mapGetters("videotag", ["tags"]),
};
</script>
