<template>
  <b-row class="mt-2">
    <b-col cols="1" class="text-center">
      <b-button
        style="min-width: 38px"
        variant="primary"
        :class="[
          'mr-2 result-positive',
          newTag && newTag.result === 'positive' ? 'active' : '',
        ]"
        @click="setNewTagField('result', 'positive')"
      >
        +
      </b-button>
      <b-button
        style="min-width: 38px"
        variant="danger"
        :class="[
          'result-negative',
          newTag && newTag.result === 'negative' ? 'active' : '',
        ]"
        @click="setNewTagField('result', 'negative')"
      >
        -
      </b-button>
    </b-col>

    <b-col cols="3" class="text-center">
      <b-button
        style="min-width: 70px"
        variant="primary"
        :class="[
          'mr-2 type-defensive',
          newTag && newTag.type === 'defensive' ? 'active' : '',
        ]"
        @click="setNewTagField('type', 'defensive')"
      >
        DEF
      </b-button>
      <b-button
        style="min-width: 70px"
        variant="primary"
        :class="[
          'mr-2 type-defensive',
          newTag && newTag.type === 'transition-' ? 'active' : '',
        ]"
        @click="setNewTagField('type', 'transition-')"
      >
        TR -
      </b-button>
      <b-button
        style="min-width: 70px"
        variant="primary"
        :class="[
          'mr-2 type-defensive',
          newTag && newTag.type === 'offensive' ? 'active' : '',
        ]"
        @click="setNewTagField('type', 'offensive')"
      >
        OFF
      </b-button>
      <b-button
        style="min-width: 70px"
        variant="primary"
        :class="[
          'type-defensive',
          newTag && newTag.type === 'transition+' ? 'active' : '',
        ]"
        @click="setNewTagField('type', 'transition+')"
      >
        TR +
      </b-button>
    </b-col>

    <b-col v-for="team in teams" :key="team.id" cols="1">
      <team-dropdown :team="team" />
    </b-col>

    <b-col cols="1">
      <b-button
        variant="primary"
        :class="[isRecording && 'active', 'w-100 rec-button']"
        @click="toggleRecording"
      >
        <b-icon icon="circle-fill"></b-icon> REC
      </b-button>
    </b-col>

    <b-col cols="1">
      <b-button variant="primary" class="w-100 playback-light" @click="rewind"
        >-10 sec</b-button
      >
    </b-col>

    <b-col cols="1">
      <b-button
        :pressed="currentPlaybackRate === 0.5"
        variant="primary"
        class="w-100 playback-light"
        @click="changePlaybackRate(0.5)"
        >0.5x</b-button
      >
    </b-col>

    <b-col v-for="rate in [1, 2]" :key="rate" cols="1">
      <b-button
        :pressed="currentPlaybackRate === rate"
        variant="primary"
        class="w-100 playback-dark"
        @click="changePlaybackRate(rate)"
        >{{ rate }}x</b-button
      >
    </b-col>

    <b-col cols="1">
      <b-row>
        <b-col>
          <b-button
            :disabled="
              !(
                newTag &&
                newTag.id &&
                currentTime >= newTag.start &&
                currentTime <= newTag.end
              )
            "
            :variant="editor ? 'secondary' : 'primary'"
            @click="addEditor()"
            title="Screenshot"
          >
            <b-icon icon="camera"></b-icon>
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="danger" @click="exitFullScreen()" title="Esci">
            <b-icon icon="box-arrow-in-right"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import _ from "lodash";
import TeamDropdown from "./TeamDropdown.vue";

export default {
  name: "NewTagFullscreen",

  data() {
    return {
      output: null,
    };
  },

  components: { TeamDropdown },

  computed: {
    ...mapGetters("videotag", ["teams"]),
    ...mapState("videotag", [
      "newTag",
      "isRecording",
      "currentPlaybackRate",
      "editor",
      "currentTime",
    ]),

    editorText() {
      return this.editor ? "Hide editor" : "Show editor";
    },
  },

  methods: {
    ...mapActions("videotag", ["addEditor"]),

    setNewTagField(key, value) {
      this.$store.commit("videotag/updateTagField", { key, value });
    },

    setTeam(team) {
      this.$store.commit("videotag/updateTagField", {
        key: "team",
        value: _.pick(team, ["id", "name"]),
      });

      this.$store.dispatch("videotag/saveTag", true);
    },

    exitFullScreen() {
      if (this.editor) {
        this.$store.commit("videotag/setEditor", false);
      }
      this.$store.commit("videotag/exitFullScreen");
    },

    toggleRecording() {
      this.$store.dispatch("videotag/toggleRecording");
    },

    rewind() {
      this.$root.$emit("updateCurrentTime", -10);
    },

    changePlaybackRate(rate) {
      this.$store.commit("videotag/changePlaybackRate", rate);
    },

    toggleEditor() {
      this.$store.commit("videotag/toggleEditor");
    },
  },
};
</script>

<style lang="scss">
.btn-primary {
  &.result-positive {
    background-color: #58ab86 !important;
    border-color: #58ab86 !important;

    &.active,
    &:hover,
    &:active {
      background-color: darken(#58ab86, 20%) !important;
      border-color: darken(#58ab86, 20%) !important;
    }

    &:active:focus,
    &.active:focus,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 171, 134, 0.5) !important;
    }
  }

  &.type-offensive {
    background-color: #ef8629 !important;
    border-color: #ef8629 !important;

    &.active,
    &:hover,
    &:active {
      background-color: darken(#ef8629, 20%) !important;
      border-color: darken(#ef8629, 20%) !important;
    }

    &:active:focus,
    &.active:focus,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 134, 41, 0.5) !important;
    }
  }

  &.type-defensive {
    background-color: #f8b431 !important;
    border-color: #f8b431 !important;

    &.active,
    &:hover,
    &:active {
      background-color: darken(#f8b431, 20%) !important;
      border-color: darken(#f8b431, 20%) !important;
    }

    &:active:focus,
    &.active:focus,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 180, 49, 0.5) !important;
    }
  }
}
</style>
