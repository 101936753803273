<template>
  <div style="display: inline-block" class="w-100">
    <b-button variant="primary" class="mr-2 w-100" v-b-modal.export-modal>
      Esporta Tag
    </b-button>

    <b-modal id="export-modal" centered title="Esporta Tag" @ok="onOk()">
      <b-form>
        <b-form-group>
          <b-list-group>
            <b-list-group-item>
              <b-form-checkbox
                v-model="allSelected"
                class="mb-0"
                @change="toggleAll()"
              >
                {{ allSelected ? "Deseleziona tutto" : "Seleziona tutto" }}
              </b-form-checkbox>
            </b-list-group-item>

            <b-form-checkbox-group v-model="selectedItems">
              <b-list-group-item v-for="tag in tags" :key="tag.id">
                <b-form-checkbox :value="tag.id" class="mb-0">
                  {{ tagDescription(tag) }}
                </b-form-checkbox>
              </b-list-group-item>
            </b-form-checkbox-group>
          </b-list-group>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "ExportTags",

  data() {
    return {
      selectedItems: [],
      allSelected: false,
    };
  },

  computed: mapGetters("videotag", ["tags"]),

  methods: {
    tagDescription(tag) {
      let result = tag.team ? tag.team.name : tag.player.name;
      result += `, ${tag.skill ? tag.skill.name : "Azione generica"}`;

      if (tag.type) result += `, ${tag.type}`;
      if (tag.result) result += `, ${tag.result}`;

      return result;
    },

    onOk() {
      this.$store.dispatch("videotag/export", this.selectedItems);
    },

    toggleAll() {
      this.selectedItems = this.allSelected ? [] : _.map(this.tags, "id");
    },
  },

  watch: {
    selectedItems() {
      this.allSelected = this.selectedItems.length === this.tags.length;
    },
  },
};
</script>
