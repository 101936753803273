<template>
  <b-card class="text-center">
    <b-img
      v-if="tag_editor.download"
      :src="tag_editor.foreground_url"
      width="80"
      style="cursor: pointer"
      @click="setEditor"
    ></b-img>
    <b-img
      v-else-if="tag_editor.image"
      :src="tag_editor.background_url"
      width="80"
      style="cursor: pointer"
      @click="setEditor"
    ></b-img>
    <b-img v-else :src="missing" width="80"></b-img>
    <div style="font-size: 0.85rem">{{ tag_editor.time }}</div>
    <b-row>
      <b-col v-if="auth.isUserEnable('down_editor')">
        <a
          v-if="tag_editor.download"
          :href="tag_editor.foreground_url"
          download
          target="_blank"
        >
          <b-icon icon="download" title="Download"></b-icon>
        </a>
        <a
          v-else-if="tag_editor.image"
          :href="tag_editor.background_url"
          download
          target="_blank"
        >
          <b-icon icon="download" title="Download"></b-icon>
        </a>
      </b-col>
      <b-col v-else> </b-col>
      <b-col>
        <b-icon
          style="cursor: pointer"
          icon="trash"
          title="Elimina"
          @click="deleteEditor"
        ></b-icon>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import tagMixin from "@/mixins/tags.js";
import { mapState, mapActions } from "vuex";
import missing from "@/assets/images/missing.jpg";
import Auth from "@/lib/auth.js";
export default {
  data: function () {
    return {
      timer: false,
      missing,
    };
  },

  props: ["tag_editor"],
  mixins: [tagMixin],

  components: {},

  created: function () {
    if (!this.tag_editor.image) {
      this.$interval = setInterval(() => {
        this.getEditor(this.tag_editor.id);
      }, 5000);
      this.timer = true;
    }
  },
  computed: {
    ...mapState("videotag", {
      fullScreen: "fullScreen",
      newTag: "newTag",
      editor: "editor",
    }),
    auth: function () {
      return Auth;
    },
  },
  methods: {
    ...mapActions("videotag", ["getEditor"]),

    deleteEditor() {
      this.confirm("Sei sicuro di voler eliminare l'editor?").then((result) => {
        if (result.value) {
          this.$store.dispatch("videotag/deleteEditor", this.tag_editor);
          clearInterval(this.$interval);
          this.timer = false;
        }
      });
    },
    setEditor() {
      this.$store.commit("videotag/resetCanvasEditor");
      this.$store.commit("videotag/setCanvasEditor", this.tag_editor);
      this.$root.$emit("canvas::loadEditor");
      if (!this.editor) {
        this.$root.$emit("apriEditor");
        this.$store.commit("videotag/setEditor", true);
      }
    },
  },
  watch: {
    tag_editor: function () {
      if (this.tag_editor.image) {
        clearInterval(this.$interval);
        this.timer = false;
      } else {
        if (!this.timer) {
          this.$interval = setInterval(() => {
            this.getEditor(this.tag_editor.id);
          }, 5000);
          this.timer = true;
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.$interval);
    this.timer = false;
  },
};
</script>
