<template>
  <b-col cols="4">
    <p class="elenco-tags text-center">ELENCO TAGS</p>

    <b-form @submit.stop.prevent="onSubmit()">
      <b-form-row>
        <b-col
          v-for="(filter, filterName, i) in filters"
          :key="`filter-${i}`"
          cols="4"
        >
          <b-form-group>
            <b-form-select v-model="selectedFields[filterName]">
              <template slot="first">
                <option value="">{{ filter.name }}</option>
              </template>

              <template v-if="valueIsArray(filter.values)">
                <option
                  v-for="(value, i) in filter.values"
                  :key="`value-${i}`"
                  :value="value.id"
                >
                  {{ value.name }}
                </option>
              </template>
              <template v-else>
                <optgroup
                  v-for="(values, labelName, i) in filter.values"
                  :key="`group-${i}`"
                  :label="labelName"
                >
                  <option
                    v-for="(value, j) in values"
                    :key="`value-${j}`"
                    :value="value.id"
                  >
                    {{ value.name }}
                  </option>
                </optgroup>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <b-form-input
              v-model="selectedFields.keyword"
              type="text"
              placeholder="Parola chiave"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2" class="col-submit">
          <b-form-group>
            <b-button type="submit" variant="success"
              ><b-icon icon="search"></b-icon
            ></b-button>
          </b-form-group>
        </b-col>

        <b-col cols="2" class="col-submit">
          <b-form-group>
            <b-button variant="primary" @click.prevent="reset()"
              ><b-icon icon="x"></b-icon
            ></b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>

    <tags />
  </b-col>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import Tags from "./Tags.vue";

export default {
  name: "TagFilters",
  components: { Tags },

  data() {
    return {
      selectedFields: {
        id_video: this.$store.state.videotag.data.video.id,
        playerOrTeam: "",
        opponent: "",
        action: "",
        player: "",
        positiveOrNegative: "",
        defensiveOrOffensive: "",
        fieldSection: "",
        keyword: "",
      },
    };
  },

  computed: {
    ...mapGetters("videotag", ["players", "tags", "categories"]),

    filters() {
      return {
        playerOrTeam: {
          name: "Tipo Tag",
          values: [
            { id: 0, name: "Squadra" },
            { id: 1, name: "Giocatore" },
            { id: 2, name: "Allenatore" },
          ],
        },
        /*     opponent: {
          name: "Avversario",
          values: [
            { id: 1, name: "Si" },
            { id: 0, name: "No" },
          ],
        },  */
        action: {
          name: "Azione",
          values: this.actionValues,
        },
        player: {
          name: "Giocatore",
          values: this.playerValues,
        },
        positiveOrNegative: {
          name: "Pos / Neg",
          values: [
            { id: 1, name: "Positivo" },
            { id: 0, name: "Negativo" },
          ],
        },
        defensiveOrOffensive: {
          name: "Def / Tr- / Off / Tr+",
          values: [
            { id: 0, name: "Difensivo" },
            { id: 2, name: "Transizioni-" },
            { id: 1, name: "Offensivo" },
            { id: 3, name: "Transizioni+" },
          ],
        },
        fieldSection: {
          name: "Sezione campo",
          values: [
            { id: 1, name: "Zona 1" },
            { id: 2, name: "Zona 2" },
            { id: 3, name: "Zona 3" },
            { id: 4, name: "Zona 4" },
            { id: 5, name: "Zona 5" },
            { id: 6, name: "Zona 6" },
            { id: 7, name: "Zona 7" },
            { id: 8, name: "Zona 8" },
            { id: 9, name: "Zona 9" },
            { id: 10, name: "Zona 10" },
            { id: 11, name: "Zona 11" },
            { id: 12, name: "Zona 12" },
            { id: 13, name: "Zona 13" },
          ],
        },
      };
    },

    actionValues() {
      return _.reduce(
        this.categories,
        (result, value) => {
          let { skills } = value;

          if (!_.isEmpty(skills)) {
            skills = _.reduce(
              skills,
              (skillResult, entry) => {
                const parent = _.pick(entry, ["id", "name"]);
                const children = entry.children
                  ? entry.children.map((e) => _.pick(e, ["id", "name"]))
                  : [];
                return skillResult.concat([parent, ...children]);
              },
              []
            );
          }

          result[value.name] = skills;
          return result;
        },
        {}
      );
    },

    playerValues() {
      return this.players.map((player) => _.pick(player, ["id", "name"]));
    },
  },

  methods: {
    valueIsArray(value) {
      return Array.isArray(value);
    },

    onSubmit() {
      this.$store.dispatch("videotag/filterTags", this.selectedFields);
    },

    reset() {
      this.selectedFields = {
        id_video: this.$store.state.videotag.data.video.id,
        playerOrTeam: "",
        opponent: "",
        action: "",
        player: "",
        positiveOrNegative: "",
        defensiveOrOffensive: "",
        fieldSection: "",
        keyword: "",
      };
      this.$store.commit("videotag/setFilteredTags", null);
      this.$store.commit("msgPush", { status: 1, msg: "Filtri cancellati!" });
    },
  },
};
</script>

<style lang="scss">
.col-submit {
  display: flex;

  .form-group {
    width: 100%;

    div {
      height: 100%;

      button {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.elenco-tags {
  /*  color: #a80532;*/
}
</style>
