<template>
  <div class="inline mr-2" style="flex: 1">
    <b-btn
      :variant="negative ? 'primary' : 'success'"
      v-b-modal="modalId"
      class="w-100"
    >
      <i class="fa fa-futbol-o" aria-hidden="true"></i>
    </b-btn>
  </div>
</template>

<script>
export default {
  name: "Goal",
  props: {
    negative: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    modalId() {
      return `modal-goal-${this.negative ? "negative" : "positive"}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}
</style>
