<template>
  <div :class="['filters-tag', tag.result]">
    <b-row style="font-size: 12px">
      <b-col class="filters-tag-badge filters-tag-id">{{ index }}</b-col>
      <b-col class="filters-tag-badge filters-tag-time">{{
        formatDuration(relativeStart)
      }}</b-col>
      <b-col
        v-if="tag.type"
        :class="['filters-tag-badge filters-tag-action', tag.type || '']"
        >{{ typeSubstring }}</b-col
      >
      <b-col
        v-if="tag.field_section"
        class="filters-tag-badge filters-tag-field-section"
        >{{ tag.field_section }}</b-col
      >
    </b-row>

    <b-row class="m-1" style="font-size: 12px">
      <b-col>
        {{ teamOrPlayerName }}
      </b-col>
    </b-row>

    <b-row class="m-1" style="font-size: 12px">
      <b-col>
        {{ skillNames }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { minBy, filter } from "lodash";

import tagMixin from "@/mixins/tags.js";

export default {
  mixins: [tagMixin],

  props: {
    tag: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },

  computed: {
    ...mapGetters("videotag", ["videoBookmarks"]),

    typeSubstring() {
      if (this.tag.type == "offensive" || this.tag.type == "defensive") {
        return this.tag.type.substring(0, 3);
      } else if (this.tag.type == "transition+") {
        return "tr+";
      } else if (this.tag.type == "transition-") {
        return "tr-";
      }
      return "";
    },

    relativeStart() {
      if (this.videoBookmarks.length === 0) return this.tag.start;

      const filteredBookmarks = filter(
        this.videoBookmarks,
        (bookmark) => Number(bookmark.start) < Number(this.tag.start)
      );

      if (!filteredBookmarks || filteredBookmarks.length === 0)
        return this.tag.start;

      const closestBookmark = minBy(filteredBookmarks, (bookmark) =>
        Math.abs(Number(this.tag.start) - Number(bookmark.start))
      );
      return Math.abs(Number(this.tag.start) - Number(closestBookmark.start));
    },
  },
};
</script>

<style lang="scss">
$background-default: rgba(0, 0, 0, 0.7);
$background-negative: rgba(166, 15, 33, 0.7);
$background-positive: rgba(55, 128, 70, 0.7);

.filters-tag-list {
  .filters-tag {
    border-radius: 4px;
    background-color: $background-default;
    color: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    &.positive {
      background-color: $background-positive;
    }

    &.negative {
      background-color: $background-negative;
    }

    .filters-tag-badge {
      border-radius: 4px;
      padding: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 3px;
      margin-right: 3px;

      &.filters-tag-id {
        background-color: black;
      }

      &.filters-tag-time {
        background-color: white;
        color: #212529;
      }

      &.filters-tag-action.offensive {
        background-color: #d3b826;
      }

      &.filters-tag-action.defensive {
        background-color: #d3800b;
      }

      &.filters-tag-field-section {
        background-color: #a2a321;
      }
    }
  }
}
</style>
