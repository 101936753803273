<template>
  <b-col>
    <div class="overlayDiv" :style="'width:' + canvas.width + 'px'">
      <canvas
        id="canvas"
        :width="canvas.width"
        :height="canvas.height"
        style="border: 2px dashed #ffffff"
      />
    </div>
  </b-col>
</template>

<script>
import { mapState, mapActions } from "vuex";

import fabric, {
  drawCurvedArrow,
  drawLine,
  setHeadAngle,
  findAngle,
  drawTriangle,
  drawRect,
  drawFlatLine,
  drawWavyLineWithArrow,
} from "@/lib/fabric.js";

export default {
  props: {
    /*  width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    }, */
  },

  data: () => ({
    line: null,
    isDown: null,

    roof: null,
    roofPoints: [],
    lines: [],
    lineCounter: 0,
    rx: 0,
    ry: 0,
  }),

  computed: mapState("videotag", ["canvas", "activeObject"]),

  watch: {
    "canvas.color.hex": function color() {
      this.$canvas.freeDrawingBrush.color = this.canvas.color.hex;
    },

    "canvas.drawingMode": function drawingMode() {
      if (this.canvas.drawingMode) {
        this.$root.$emit("canvas::clearSelection");
        this.$store.commit("videotag/removeActiveObject");
      }
      this.$canvas.set("isDrawingMode", this.canvas.drawingMode);
    },

    "canvas.lineWidth": function lineWidth() {
      this.$canvas.freeDrawingBrush.width = this.canvas.lineWidth;
    },

    "canvas.wavy": function toggleWavy() {
      if (this.canvas.wavy) {
        this.$root.$emit("canvas::clearSelection");
        this.$store.commit("videotag/removeActiveObject");
      }
      this.changeObjSelection(!this.canvas.wavy);
    },
  },

  mounted() {
    this.$canvas = new fabric.Canvas("canvas");
    //   window.canvas = this.$canvas;

    this.$canvas.on("object:moving", this.onObjectMoving);
    this.$canvas.on("object:selected", this.onObjectSelected);
    this.$canvas.on("before:selection:cleared", this.onBeforeSelectionCleared);
    this.$canvas.on("mouse:up", this.onMouseUp);
    this.$canvas.on("mouse:over", this.onMouseOver);
    this.$canvas.on("mouse:out", this.onMouseOut);

    this.$canvas.on("mouse:down", this.onMouseDown);
    this.$canvas.on("mouse:move", this.onMouseMove);

    this.$canvas.on("mouse:dblclick", this.onMouseDblclick);

    this.$root.$on("canvas::addObject", this.addObject);
    this.$root.$on("canvas::deleteObjects", this.shouldRemoveObject);
    this.$root.$on("canvas::insertText", this.insertText);
    this.$root.$on("canvas::insertCurvedArrow", this.insertCruvedArrow);
    this.$root.$on("canvas::insertLine", (pointer) => this.insertLine(pointer));
    this.$root.$on("canvas::insertDashedLine", (pointer) =>
      this.insertLine(pointer, true)
    );
    this.$root.$on("canvas::insertTriangle", this.insertTriangle);
    this.$root.$on("canvas::insertRect", this.insertRect);
    this.$root.$on("canvas::insertFlatLine", this.insertFlatLine);
    this.$root.$on("canvas::insertFlatDashedLine", this.insertFlatDashedLine);
    this.$root.$on("canvas::clearSelection", this.clearSelection);
    this.$root.$on("canvas::cloneObjects", this.cloneObjects);
    this.$root.$on("canvas::download", this.download);
    this.$root.$on("canvas::save", this.save);
    this.$root.$on("canvas::clearRoof", this.clearRoof);
    this.$root.$on("canvas::clearPathRoof", this.clearPathRoof);

    this.$root.$on("canvas::loadEditor", this.loadEditor);

    this.loadEditor();
  },
  beforeDestroy() {
    this.$canvas.dispose();
    this.$root.$off("canvas::addObject");
    this.$root.$off("canvas::deleteObjects");
    this.$root.$off("canvas::insertText");
    this.$root.$off("canvas::insertCurvedArrow");
    this.$root.$off("canvas::insertLine");
    this.$root.$off("canvas::insertDashedLine");
    this.$root.$off("canvas::insertTriangle");
    this.$root.$off("canvas::insertRect");
    this.$root.$off("canvas::clearSelection");
    this.$root.$off("canvas::cloneObjects");
    this.$root.$off("canvas::download");
    this.$root.$off("canvas::save");
    this.$root.$off("canvas::clearRoof");
    this.$root.$off("canvas::clearPathRoof");
    this.$root.$off("canvas::loadEditor");
  },

  methods: {
    ...mapActions("videotag", ["updateEditor"]),

    /*  addObject(object, coords) {

      console.log('addObject ' + JSON.stringify(object));

      const url = typeof object === "string" ? object : object.imageUrl;
      const center = this.$canvas.getCenter();

      fabric.loadSVGFromURL(url, (objects, options) => {
        const obj = fabric.util.groupSVGElements(
          objects,
          {
            ...options,
            originX: "center",
            originY: "center",
            left: coords ? coords.x : center.left,
            top: coords ? coords.y : center.top,
            cornerSize: 8,
            cornerStyle: "circle",
            cornerColor: "black",
            borderColor: "black",
            transparentCorners: false,
            padding: 0,
            borderDashArray: [3, 3],
          },
          url
        );
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          mr: false,
          ml: false,
        });
        obj.set({
          scaleX: 0.4,
          scaleY: 0.4,
        });
        this.$canvas.add(obj).renderAll();
      });
    }, */

    loadEditor() {
      this.$canvas.clear();
      this.$canvas.backgroundImage = null;
      if (this.canvas.export) {
        this.loadCanvasFromJson();
      } else if (!this.$canvas.backgroundImage && this.canvas.background) {
        this.setCanvasBackground(this.canvas.background);
      }
    },

    addObject(object, coords) {
      var w = document.getElementsByClassName("canvas-container")[0]
        .offsetWidth;
      var h = document.getElementsByClassName("canvas-container")[0]
        .offsetHeight;

      const imageUrl = typeof object === "string" ? object : object.image_url;

      var scaleObj = 1;

      var quarto = h / 4;

      if (coords.y < quarto * 2) {
        scaleObj = h * 0.0008;
      } else if (coords.y >= quarto * 2 && coords.y <= quarto * 3) {
        scaleObj = h * 0.0012;
      }
      //var scaleObj = (w * 1) / 720;

      fabric.util.loadImage(
        imageUrl,
        (imgData) => {
          const img = new fabric.ObjectImage(imgData, {
            left: coords ? coords.x : 100,
            top: coords ? coords.y : 100,
            scaleX: scaleObj,
            scaleY: scaleObj,
          });

          img.setControlsVisibility({
            mt: false,
            mb: false,
            mr: false,
            ml: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
          });

          this.$canvas.add(img);
        },
        null,
        { crossOrigin: "Anonymous" }
      );
    },

    /*    loadCanvasFromJson() {
      const data = JSON.parse(this.canvas.export);

      data.objects = data.objects.map((object) => {
        const result = object;
        if (result.type === "image") {
          result.crossOrigin = "anonymous";
        }
        return result;
      });
    },  */

    loadCanvasFromJson() {
      const data = JSON.parse(this.canvas.export);
      let backgroundImage = null;
      if (data.backgroundImage && data.backgroundImage.sourcePath) {
        // eslint-disable-next-line prefer-destructuring
        backgroundImage = data.backgroundImage;
        delete data.backgroundImage;
      }

      data.objects = data.objects.map((object) => {
        const result = object;
        if (result.type === "image") {
          result.crossOrigin = "anonymous";
        }
        return result;
      });

      if (data.backgroundImage) {
        data.backgroundImage.crossOrigin = "anonymous";
      }

      this.$canvas.loadFromJSON(
        data,
        this.$canvas.renderAll.bind(this.$canvas),
        () => {
          if (backgroundImage)
            this.setCanvasBackground(backgroundImage.sourcePath);
        }
      );
    },

    setCanvasBackground(url) {
      fabric.util.loadImage(
        url,
        (imgData) => {
          const img = new fabric.Image(imgData, {
            originY: "top",
            originX: "left",
          });

          /* manage height and scale canvas start */

          var canvas_container_w = document.getElementsByClassName(
            "canvas-container"
          )[0].offsetWidth;
          var canvas_container_h = document.getElementsByClassName(
            "canvas-container"
          )[0].offsetHeight;

          //  var scale = (1 * canvas_container_w) / 720;

          img.set({
            scaleX: canvas_container_w / img.width,
            scaleY: canvas_container_h / img.height,
          });
          /* manage height and scale canvas end */

          this.$canvas.setBackgroundImage(
            img,
            this.$canvas.renderAll.bind(this.$canvas)
          );
        },
        null,
        { crossOrigin: "Anonymous" }
      );
    },

    insertText(pointer) {
      const textObj = new fabric.IText("", {
        left: pointer.x,
        top: pointer.y,
        fill: this.canvas.color.hex,
        fontSize: 24,
        fontFamily: "Helvetica",
        cornerSize: 8,
        cornerStyle: "circle",
        cornerColor: "black",
        borderColor: "black",
        transparentCorners: false,
        padding: 0,
        borderDashArray: [3, 3],
      });

      this.$canvas.add(textObj);
      this.$canvas.setActiveObject(textObj);
      textObj.enterEditing();
      this.$canvas.renderAll();
    },

    insertCruvedArrow(pointer) {
      drawCurvedArrow(this.canvas.color.hex, this.$canvas, pointer);
    },

    insertLine(pointer, dashed = false) {
      drawLine(this.$canvas, this.canvas.color.hex, dashed, pointer);
    },

    insertTriangle(pointer) {
      drawTriangle(this.$canvas, this.canvas.color.hex, pointer);
    },

    insertRect(pointer) {
      drawRect(this.$canvas, this.canvas.color.hex, pointer);
    },

    insertFlatLine(pointer) {
      drawFlatLine(this.$canvas, this.canvas.color.hex, false, pointer);
    },

    insertFlatDashedLine(pointer) {
      drawFlatLine(this.$canvas, this.canvas.color.hex, true, pointer);
    },

    exportCanvas() {
      this.$store.commit(
        "videotag/export",
        JSON.stringify(
          this.$canvas.toDatalessJSON([
            "originX",
            "originY",
            "width",
            "height",
            "toBeParsed",
            "viewboxTransform",
            "crossOrigin",
            "svgUid",
            "left",
            "top",
            "cornerSize",
            "cornerStyle",
            "cornerColor",
            "borderColor",
            "transparentCorners",
            "padding",
            "borderDashArray",
            "oCoords",
            "aCoords",
            "sourcePath",
            "_controlsVisibility",
            "scaleX",
            "scaleY",
            "objectCaching",
          ])
        )
      );
    },

    exportImage() {
      this.$store.commit(
        "videotag/exportImage",
        this.$canvas.toDataURL("png")
        /*  this.$canvas.toDataURL({
          format: "png",
          quality: 1,
          multiplier: 1,
          enableRetinaScaling: true,
        })  */
      );
    },

    download() {
      this.exportImage();
      this.downloadTacticalBoard()
        .then(() => {
          this.$notification({
            color: "success",
            text: this.$t("videotags.download"),
          });
        })
        .catch(() => {
          this.$notification({
            color: "error",
            text: this.$t("errors.error500"),
          });
        });
    },

    save() {
      this.exportCanvas();
      this.exportImage();
      this.updateEditor();
      /*   .then(() => {
          this.$notification({
            color: "success",
            text: this.$t("videotags.tacticalBoardSaved"),
          });
        })
        .catch(() => {
          this.$notification({
            color: "error",
            text: this.$t("errors.error500"),
          });
        }); */
    },

    shouldRemoveObject() {
      this.$canvas.getActiveObjects().forEach((obj) => {
        if (obj.groupId) {
          this.$canvas.remove(
            ...this.$canvas
              .getObjects()
              .filter((o) => o.groupId === obj.groupId)
          );
        } else {
          this.$canvas.remove(obj);
        }
      });
    },

    cloneObjects() {
      if (this.$canvas.getActiveObject()) {
        this.$canvas.getActiveObject().clone((clonedObj) => {
          this.$canvas.discardActiveObject();
          clonedObj.set({
            left: clonedObj.left + 50,
            top: clonedObj.top,
            evented: true,
          });
          if (clonedObj.type === "activeSelection") {
            clonedObj.canvas = this.$canvas;
            clonedObj.forEachObject((obj2) => {
              this.$canvas.add(obj2);
            });
            clonedObj.setCoords();
          } else {
            this.$canvas.add(clonedObj);
          }
          this.$canvas.setActiveObject(clonedObj);
          this.$canvas.requestRenderAll();
        });
      }
    },

    findObjectById(id) {
      return this.$canvas.getObjects().find((obj) => obj.id === id);
    },

    onObjectSelected(evt) {
      const activeObject = evt.target;
      if (!activeObject) return;

      this.$store.commit("videotag/removeActiveObject");

      if (activeObject.name === "tail" || activeObject.name === "head") {
        const centerPoint = this.findObjectById(activeObject.centerPoint);
        centerPoint.animate("opacity", 1, {
          duration: 200,
          onChange: this.$canvas.renderAll.bind(this.$canvas),
        });
        centerPoint.selectable = true;
      } else {
        activeObject.set({
          cornerSize: 8,
          cornerStyle: "circle",
          cornerColor: "black",
          borderColor: "black",
          transparentCorners: false,
          padding: 0,
          borderDashArray: [3, 3],
        });
      }
    },

    onMouseOver(evt) {
      const activeObject = evt.target;
      if (
        !activeObject ||
        !["arrowStart", "arrowEnd", "centerPoint", "head", "tail"].includes(
          activeObject.name
        )
      ) {
        return;
      }

      activeObject.set({
        scaleX: 2,
        scaleY: 2,
      });

      this.$canvas.renderAll();
    },

    onMouseOut(evt) {
      const activeObject = evt.target;
      if (
        !activeObject ||
        !["arrowStart", "arrowEnd", "centerPoint", "head", "tail"].includes(
          activeObject.name
        )
      ) {
        return;
      }

      activeObject.set({
        scaleX: 1,
        scaleY: 1,
      });

      this.$canvas.renderAll();
    },

    onBeforeSelectionCleared(evt) {
      const activeObject = evt.target;

      if (["tail", "head", "centerPoint"].includes(activeObject.name)) {
        const target =
          activeObject.name === "centerPoint"
            ? activeObject
            : this.findObjectById(activeObject.centerPoint);

        if (!target) return;

        target.animate("opacity", 0, {
          duration: 200,
          onChange: this.$canvas.renderAll.bind(this.$canvas),
        });
        target.selectable = false;
      }
    },

    onObjectMoving(evt) {
      const activeObject = evt.target;

      const line = this.findObjectById(activeObject.line);
      const head = this.findObjectById(activeObject.head);
      const centerPoint = this.findObjectById(activeObject.centerPoint);
      const tail = this.findObjectById(activeObject.tail);

      if (activeObject.name === "centerPoint") {
        if (line) {
          line.path[1][1] = activeObject.left;
          line.path[1][2] = activeObject.top;
        }

        if (head) {
          setHeadAngle(head, activeObject);
        }
      } else if (activeObject.name === "tail") {
        if (line) {
          line.path[0][1] = activeObject.left;
          line.path[0][2] = activeObject.top;
        }

        if (head && centerPoint) {
          setHeadAngle(head, centerPoint);
        }
      } else if (activeObject.name === "head") {
        if (line) {
          line.path[1][3] = activeObject.left;
          line.path[1][4] = activeObject.top;
        }

        if (centerPoint) {
          setHeadAngle(activeObject, centerPoint);
        }
      } else if (
        activeObject.pointType === "arrowStart" ||
        activeObject.pointType === "arrowEnd"
      ) {
        if (activeObject.pointType === "arrowStart") {
          line.set({
            x1: activeObject.left,
            y1: activeObject.top,
            x2: tail.left,
            y2: tail.top,
          });
        } else {
          line.set({
            x1: head.left,
            y1: head.top,
            x2: activeObject.left,
            y2: activeObject.top,
          });
        }

        // eslint-disable-next-line no-underscore-dangle
        line._setWidthHeight();

        const x = line.get("x2") - line.get("x1");
        const y = line.get("y2") - line.get("y1");
        const angle = findAngle(x, y);

        if (activeObject.pointType === "arrowStart") {
          activeObject.set("angle", angle - 90);
        } else {
          head.set("angle", angle - 90);
        }
      }
    },

    clearSelection() {
      this.$canvas.discardActiveObject();
      this.$canvas.renderAll();
    },

    onMouseUp(evt) {
      this.isDown = false;
      if (this.canvas.wavy) {
        if (this.line) {
          this.line.setCoords();
        }
        this.$canvas.requestRenderAll();
      }

      const activeObject = evt.target;
      if (!activeObject) {
        if (this.activeObject) {
          if (typeof this.activeObject === "string") {
            this.$root.$emit(`canvas::insert${this.activeObject}`, evt.pointer);
          } else {
            if (!this.activeObject.is_path) {
              this.addObject(this.activeObject, evt.pointer);
            }
          }
        }
      } else if (activeObject.name === "bezierArrow") {
        this.$store.commit("videotags/removeActiveObject");

        const centerPoint = this.findObjectById(activeObject.centerPoint);
        centerPoint.animate("opacity", 1, {
          duration: 200,
          onChange: this.$canvas.renderAll.bind(this.$canvas),
        });
        centerPoint.selectable = true;
      }

      if (
        activeObject &&
        ["tail", "head", "centerPoint"].includes(activeObject.name)
      ) {
        const target = this.findObjectById(activeObject.line);
        if (target.name === "bezierArrow") {
          const opts = {
            id: target.id,
            groupId: target.groupId,
            stroke: target.stroke,
            tail: target.tail,
            head: target.head,
            centerPoint: target.centerPoint,
            fill: "",
          };

          this.$canvas.remove(target);
          const newPath = new fabric.BezierPath(target.path, opts);
          this.$canvas.add(newPath);
        }
      }
    },

    changeObjSelection(value) {
      this.$canvas.selection = value;
      this.$canvas.forEachObject((obj) => {
        /* eslint no-param-reassign: "error" */
        obj.selectable = value;
        obj.hoverCursor = value ? "move" : "default";
      });
      this.$canvas.requestRenderAll();
    },

    onMouseDown(options) {
      this.isDown = true;
      if (this.canvas.wavy) {
        const pointer = this.$canvas.getPointer(options.e);
        const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        this.line = this.selectLine(points);
        this.$canvas.add(this.line);
      }

      if (
        this.activeObject == "roof" ||
        (this.activeObject && this.activeObject.is_path)
      ) {
        this.$canvas.selection = false;
        this.setStartingPoint(options);
        this.roofPoints.push(new this.Point(this.rx, this.ry));
        var points = [this.rx, this.ry, this.rx, this.ry];
        this.lines.push(
          new fabric.Line(points, {
            strokeWidth: 3,
            selectable: false,
            stroke: "red",
          })
        );
        this.$canvas.add(this.lines[this.lineCounter]);
        this.lineCounter++;
      }
    },

    onMouseMove(options) {
      if (this.canvas.wavy) {
        if (!this.isDown) return;
        const pointer = this.$canvas.getPointer(options.e);
        this.line.set({
          x2: pointer.x,
          y2: pointer.y,
        });
        this.$canvas.renderAll();
      }
      if (
        this.lines[0] !== null &&
        this.lines[0] !== undefined &&
        (this.activeObject == "roof" || this.activeObject.is_path)
      ) {
        this.setStartingPoint(options);
        this.lines[this.lineCounter - 1].set({
          x2: this.rx,
          y2: this.ry,
        });
        this.$canvas.renderAll();
      }
    },

    selectLine(points) {
      return drawWavyLineWithArrow(
        points,
        this.canvas.color.hex,
        this.canvas.dashed
      );
    },

    onMouseDblclick(options) {
      if (
        this.lines[0] !== null &&
        this.lines[0] !== undefined &&
        this.activeObject == "roof"
      ) {
        this.$store.commit("videotag/removeActiveObject");
        var canvas = this.$canvas;
        this.lines.forEach(function (value, index, ar) {
          canvas.remove(value);
        });

        this.roof = this.makeRoof(this.roofPoints);
        this.$canvas.add(this.roof);

        this.$canvas.renderAll();
        this.resetRoof();
      } else if (
        this.lines[0] !== null &&
        this.lines[0] !== undefined &&
        this.activeObject.is_path
      ) {
        var canvas = this.$canvas;
        this.lines.forEach(function (value, index, ar) {
          canvas.remove(value);
        });

        this.roof = this.makePath(this.roofPoints);
        this.$canvas.add(this.roof);
        for (var i = 0; i < this.roofPoints.length - 1; i++) {
          this.addObject(this.activeObject.image_url, {
            x: this.roofPoints[i].x,
            y: this.roofPoints[i].y,
          });
        }
        this.$store.commit("videotag/removeActiveObject");
        this.$canvas.renderAll();
        this.resetRoof();
      }
    },

    Point(x, y) {
      this.x = x;
      this.y = y;
    },

    setStartingPoint(options) {
      const pointer = this.$canvas.getPointer(options.e);
      this.rx = pointer.x;
      this.ry = pointer.y;
    },

    makeRoof(roofPoints) {
      this.roofPoints.push(new this.Point(roofPoints[0].x, roofPoints[0].y));
      var rgba =
        "rgba(" +
        this.canvas.color.rgba.r +
        "," +
        this.canvas.color.rgba.g +
        "," +
        this.canvas.color.rgba.b +
        ",0.2)";
      var roof = new fabric.Polyline(roofPoints, {
        perPixelTargetFind: true,
        fill: rgba,
      });
      return roof;
    },

    makePath(roofPoints) {
      var roof = new fabric.Polyline(roofPoints, {
        fill: "rgba(0,0,0,0)",
        perPixelTargetFind: true,
        stroke: this.canvas.color.hex,
      });
      return roof;
    },

    resetRoof() {
      this.roofPoints = [];
      this.lines = [];
      this.lineCounter = 0;
      this.roof = null;
    },

    clearRoof() {
      var canvas = this.$canvas;
      this.lines.forEach(function (value, index, ar) {
        canvas.remove(value);
      });
      if (this.roofPoints.length > 0) {
        this.roof = this.makeRoof(this.roofPoints);
        this.$canvas.add(this.roof);
        this.$canvas.renderAll();
      }

      this.roofPoints = [];
      this.lines = [];
      this.lineCounter = 0;
      this.roof = null;
    },

    clearPathRoof() {
      var canvas = this.$canvas;
      this.lines.forEach(function (value, index, ar) {
        canvas.remove(value);
      });
      if (this.roofPoints.length > 0) {
        this.roof = this.makePath(this.roofPoints);
        this.$canvas.add(this.roof);
        this.$canvas.renderAll();
      }

      this.roofPoints = [];
      this.lines = [];
      this.lineCounter = 0;
      this.roof = null;
    },
  },
};
</script>
<style scoped>
.overlayDiv {
  text-align: center;
  margin: 0px auto;
  /* background: rgba(255, 255, 255, 0.5);*/
  /*  position: absolute;
  top: 0;*/
  /*  left: 0; */
}
</style>
