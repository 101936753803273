<template>
  <b-sidebar
    id="editor-bar"
    width="4.4em"
    v-model="editorMode"
    title="Sidebar"
    sidebar-class="editor-bar"
    right
    shadow
    :no-header-close="true"
    :no-header="true"
    bg-variant="dark-transparent"
    class="editor-bar"
  >
    <b-row>
      <b-col class="px-4 pt-1">
        <div class="icon-editor" id="popover-color" title="Seleziona colore">
          <b-icon icon="brush" style="color: #ffffff" font-scale="3"></b-icon>
        </div>
        <b-popover target="popover-color" placement="bottom">
          <compact
            :value="color"
            @input="changeColor"
            :palette="[
              'black',
              'white',
              'gray',
              'red',
              'blue',
              'green',
              'orange',
              'yellow',
              'purple',
              'navy',
              'aqua',
              'lime',
            ]"
          />
        </b-popover>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="
            activeObject === 'Text' ? 'icon-editor-active' : 'icon-editor'
          "
          title="Inserisci testo"
          @click="clearAndSetActiveObject('Text')"
        >
          <icona1 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="drawingMode ? 'icon-editor-active' : 'icon-editor'"
          title="Disegno libero"
          @click="toggleDrawingMode"
        >
          <icona2 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="
            activeObject === 'Line' ? 'icon-editor-active' : 'icon-editor'
          "
          title="Freccia"
          @click="clearAndSetActiveObject('Line')"
        >
          <icona9 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="
            activeObject === 'DashedLine' ? 'icon-editor-active' : 'icon-editor'
          "
          title="Freccia tratteggiata"
          @click="clearAndSetActiveObject('DashedLine')"
        >
          <icona3 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="
            activeObject === 'FlatLine' ? 'icon-editor-active' : 'icon-editor'
          "
          title="Linea"
          @click="clearAndSetActiveObject('FlatLine')"
        >
          <icona4 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div class="icon-editor" id="popover-ring">
          <icona5 />
        </div>
        <b-popover target="popover-ring" placement="top">
          <div
            :class="
              activeObject && activeObject.id === 'ring_blue'
                ? 'p-2 mb-1 icon-editor-active'
                : 'p-2 mb-1 icon-editor'
            "
            @click="
              clearAndSetActiveObject({ id: 'ring_blue', image_url: ringBlue })
            "
            style="cursor: pointer"
          >
            <b-img :src="ringBlue" width="50" />
          </div>
          <div
            :class="
              activeObject && activeObject.id === 'ring_red'
                ? 'p-2 mb-1 icon-editor-active'
                : 'p-2 mb-1 icon-editor'
            "
            @click="
              clearAndSetActiveObject({ id: 'ring_red', image_url: ringRed })
            "
            style="cursor: pointer"
          >
            <b-img :src="ringRed" width="50" />
          </div>
          <div
            :class="
              activeObject && activeObject.id === 'ring_yellow'
                ? 'p-2 mb-1 icon-editor-active'
                : 'p-2 mb-1 icon-editor'
            "
            @click="
              clearAndSetActiveObject({
                id: 'ring_yellow',
                image_url: ringYellow,
              })
            "
            style="cursor: pointer"
          >
            <b-img :src="ringYellow" width="50" />
          </div>
        </b-popover>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="
            activeObject === 'roof' ? 'icon-editor-active' : 'icon-editor'
          "
          title="Seleziona area"
          @click="clearAndSetActiveObject('roof')"
        >
          <icona6 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div
          :class="
            activeObject && activeObject.id === 'cono'
              ? 'icon-editor-active'
              : 'icon-editor'
          "
          title="Seleziona cono"
          @click="clearAndSetActiveObject({ id: 'cono', image_url: cono })"
        >
          <icona7 />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div class="icon-editor" id="popover-path">
          <icona8 />
          <b-popover target="popover-path" placement="top">
            <div
              :class="
                activeObject && activeObject.id === 'path_ring_blue'
                  ? 'p-2 mb-1 icon-editor-active'
                  : 'p-2 mb-1 icon-editor'
              "
              @click="
                clearAndSetActiveObject({
                  id: 'path_ring_blue',
                  image_url: ringBlue,
                  is_path: true,
                })
              "
              style="cursor: pointer"
            >
              <b-img :src="ringBlue" width="50" />
            </div>
            <div
              :class="
                activeObject && activeObject.id === 'path_ring_red'
                  ? 'p-2 mb-1 icon-editor-active'
                  : 'p-2 mb-1 icon-editor'
              "
              @click="
                clearAndSetActiveObject({
                  id: 'path_ring_red',
                  image_url: ringRed,
                  is_path: true,
                })
              "
              style="cursor: pointer"
            >
              <b-img :src="ringRed" width="50" />
            </div>
            <div
              :class="
                activeObject && activeObject.id === 'path_ring_yellow'
                  ? 'p-2 mb-1 icon-editor-active'
                  : 'p-2 mb-1 icon-editor'
              "
              @click="
                clearAndSetActiveObject({
                  id: 'path_ring_yellow',
                  image_url: ringYellow,
                  is_path: true,
                })
              "
              style="cursor: pointer"
            >
              <b-img :src="ringYellow" width="50" />
            </div>
          </b-popover>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div class="icon-editor" @click="deleteCanvasObject">
          <b-icon icon="trash" style="color: #ffffff" font-scale="3"></b-icon>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div class="icon-editor" @click="saveEditor">
          <b-icon icon="folder" style="color: #ffffff" font-scale="3"></b-icon>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-4 pt-1">
        <div class="icon-editor" @click="chiudiEditor">
          <b-icon
            icon="box-arrow-right"
            style="color: #ffffff"
            font-scale="3"
          ></b-icon>
        </div>
      </b-col>
    </b-row>
  </b-sidebar>
</template>

<script>
import { Compact } from "vue-color";
import { mapState, mapMutations } from "vuex";
import Icona1 from "@/assets/images/editor/icone/icona1.svg";
import Icona2 from "@/assets/images/editor/icone/icona2.svg";
import Icona3 from "@/assets/images/editor/icone/icona3.svg";
import Icona4 from "@/assets/images/editor/icone/icona4.svg";
import Icona5 from "@/assets/images/editor/icone/icona5.svg";
import Icona6 from "@/assets/images/editor/icone/icona6.svg";
import Icona7 from "@/assets/images/editor/icone/icona7.svg";
import Icona8 from "@/assets/images/editor/icone/icona8.svg";
import Icona9 from "@/assets/images/editor/icone/icona9.svg";
import ringBlue from "@/assets/images/editor/oggetti/ring_blue.png";
import ringRed from "@/assets/images/editor/oggetti/ring_red.png";
import ringYellow from "@/assets/images/editor/oggetti/ring_yellow.png";
import cono from "@/assets/images/editor/oggetti/cono.png";
export default {
  data: function () {
    return {
      ringBlue,
      ringRed,
      ringYellow,
      cono,
    };
  },

  components: {
    Compact,
    Icona1,
    Icona2,
    Icona3,
    Icona4,
    Icona5,
    Icona6,
    Icona7,
    Icona8,
    Icona9,
  },

  created: function () {},
  computed: {
    ...mapState("videotag", {
      editor: "editor",
      activeObject: "activeObject",
      drawingMode: (state) => state.canvas.drawingMode,
      height: (state) => state.canvas.height,
      color: (state) => state.canvas.color,
      fullScreen: "fullScreen",
      newTag: "newTag",
      currentTime: "currentTime",
    }),

    editorMode: {
      get() {
        return this.editor;
      },
      set(newValue) {
        this.$store.commit("videotag/setEditor", newValue);
        /*    if (newValue) {
          this.$root.$emit("canvas::clearSelection");
          this.$store.commit("videotag/removeActiveObject");
          this.$store.commit("videotag/removeDrawingMode");
          this.$store.commit("videotag/removeWavy");
          this.$root.$emit("apriEditor");
        } else {
          this.$root.$emit("chiudiEditor");
        } */
      },
    },

    /*  cssVars() {
      return {
        '--height': this.height + 'px !important'
      }
    } */
  },
  methods: {
    ...mapMutations("videotag", ["setActiveObject"]),

    clearAndSetActiveObject(obj) {
      if (obj === "roof" && this.activeObject == "roof") {
        this.$root.$emit("canvas::clearRoof");
      } else if (
        obj &&
        obj.is_path &&
        this.activeObject &&
        this.activeObject.is_path
      ) {
        this.$root.$emit("canvas::clearPathRoof");
      }
      this.$root.$emit("canvas::clearSelection");
      this.setActiveObject(obj);
      this.objectDialog = false;
    },
    toggleDrawingMode() {
      this.$store.commit("videotag/toggleDrawingMode");
    },
    deleteCanvasObject() {
      this.$root.$emit("canvas::deleteObjects");
    },
    changeColor(color) {
      this.$store.commit("videotag/setCanvasColor", color);
    },
    saveEditor() {
      this.$root.$emit("canvas::save");
    },
    chiudiEditor() {
      this.$store.commit("videotag/setEditor", false);
      this.$root.$emit("chiudiEditor");
    },
  },
  watch: {},
};
</script>
<style>
.icon-editor {
  cursor: pointer;
  padding: 2px 2px 2px 2px;
}

.icon-editor-active {
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  background: black;
}

.icon-editor:hover {
  background: black;
}

.editor-bar {
  /*  position:  absolute !important;*/
  /*height: var(--height);
  max-height: var(--height); */
}
</style>
