var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"60vh","overflow-x":"hidden"},attrs:{"data-simplebar":""}},_vm._l((_vm.teamAndPlayers),function(player,i){return _c('b-row',{key:i},[_c('b-col',[_c('b-button',{class:[
          'w-100',
          !player.isTeam && _vm.currentPlayerId === player.id
            ? 'current-player'
            : player.isCoach
            ? 'coach'
            : '' ],staticStyle:{"font-size":"0.8rem"},attrs:{"size":"sm","variant":"primary","disabled":!player.isTeam &&
          ((_vm.goalkeeperMode && !player.portiere) ||
            (!_vm.goalkeeperMode && player.portiere)),"title":player.name + (player.isCoach ? ' (coach)' : '')},on:{"click":function($event){return _vm.setPlayerOrTeam(player)}}},[_vm._v(" "+_vm._s(player.name)+" "),(player.changed)?_c('i',{staticClass:"fa fa-arrow-up player-changed",attrs:{"aria-hidden":"true"}}):_vm._e()])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }