<template>
  <div style="height: 60vh; overflow-x: hidden" data-simplebar>
    <b-row v-for="(player, i) in teamAndPlayers" :key="i">
      <b-col>
        <b-button
          style="font-size: 0.8rem"
          size="sm"
          variant="primary"
          :disabled="
            !player.isTeam &&
            ((goalkeeperMode && !player.portiere) ||
              (!goalkeeperMode && player.portiere))
          "
          :class="[
            'w-100',
            !player.isTeam && currentPlayerId === player.id
              ? 'current-player'
              : player.isCoach
              ? 'coach'
              : '',
          ]"
          @click="setPlayerOrTeam(player)"
          :title="player.name + (player.isCoach ? ' (coach)' : '')"
        >
          {{ player.name }}
          <i
            v-if="player.changed"
            class="fa fa-arrow-up player-changed"
            aria-hidden="true"
          ></i>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import "simplebar";
import _ from "lodash";

export default {
  name: "Team",
  props: ["team"],

  computed: {
    ...mapState("videotag", [
      "newTag",
      "playing",
      "currentTime",
      "goalkeeperMode",
    ]),
    ...mapGetters("videotag", ["currentPlayerId"]),

    teamAndPlayers() {
      const result = [
        { id: this.team.id, name: this.team.name, isTeam: true },
        ...(this.team.coach
          ? [
              {
                id: this.team.coach.id,
                name: this.team.coach.name,
                isCoach: true,
              },
            ]
          : []),
      ].concat(_.filter(this.team.players, "visible"));
      return result;
    },
  },

  methods: {
    setPlayerOrTeam(player) {
      this.$store.commit("videotag/updateTagField", {
        key: player.isTeam ? "team" : player.isCoach ? "coach" : "player",
        value: _.pick(player, ["id", "name"]),
      });

      if (!this.newTag.editing) {
        this.$store.dispatch("videotag/saveTag");
      }
    },

    checkForChanges() {
      if (!this.team.changes) return;

      const currentSeconds = parseInt(this.currentTime, 10);
      _.each(this.team.changes, (change) => {
        let leavingPlayer = null;
        let enteringPlayer = null;

        _.each(this.team.players, (player) => {
          if (player.id === change.leaving_player_id) leavingPlayer = player;
          if (player.id === change.entering_player_id) {
            player.changed = true;
            enteringPlayer = player;
          }
        });

        if (!leavingPlayer || !enteringPlayer) return;

        if (change.start < currentSeconds) {
          this.$set(leavingPlayer, "visible", false);
          this.$set(enteringPlayer, "visible", true);
        } else {
          this.$set(leavingPlayer, "visible", true);
          this.$set(enteringPlayer, "visible", false);
        }
      });
    },
  },

  watch: {
    playing() {
      if (this.playing) {
        this.$interval = setInterval(() => {
          this.checkForChanges();
        }, 1000);
      } else {
        clearInterval(this.$interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.player-changed {
  color: darken($color: #ffffff, $amount: 20);
  margin-left: 10px;
}

.row:not(:last-child) {
  margin-bottom: 3px;
}

.col {
  padding-right: 3px;
  padding-left: 3px;
}

.btn {
  width: 100%;
  font-weight: 400;
  font-size: 0.9rem;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
</style>
