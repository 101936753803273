<template id="flag">
  <span>
    <img v-bind:src="image" v-bind:alt="country" @error="imageLoadError" />
  </span>
</template>

<script>
export default {
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      hasError: false,
    };
  },

  props: {
    country: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      default: 24,
    },
  },

  computed: {
    image: function () {
      return this.hasError
        ? `${this.publicPath}assets/images/flags/${this.size}/_unknown.png`
        : `${this.publicPath}assets/images/flags/${
            this.size
          }/${this.country.toUpperCase()}.png`;
    },
  },

  watch: {
    country: function () {
      this.hasError = false;
    },
  },

  methods: {
    imageLoadError() {
      this.hasError = true;
    },
  },
};
</script>
