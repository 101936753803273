var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('b-button',{class:[
        'mr-2 result-positive',
        _vm.newTag && _vm.newTag.result === 'positive' ? 'active' : '' ],staticStyle:{"min-width":"38px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setNewTagField('result', 'positive')}}},[_vm._v(" + ")]),_c('b-button',{class:[
        'result-negative',
        _vm.newTag && _vm.newTag.result === 'negative' ? 'active' : '' ],staticStyle:{"min-width":"38px"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.setNewTagField('result', 'negative')}}},[_vm._v(" - ")])],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('b-button',{class:[
        'mr-2 type-defensive',
        _vm.newTag && _vm.newTag.type === 'defensive' ? 'active' : '' ],staticStyle:{"min-width":"70px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setNewTagField('type', 'defensive')}}},[_vm._v(" DEF ")]),_c('b-button',{class:[
        'mr-2 type-defensive',
        _vm.newTag && _vm.newTag.type === 'transition-' ? 'active' : '' ],staticStyle:{"min-width":"70px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setNewTagField('type', 'transition-')}}},[_vm._v(" TR - ")]),_c('b-button',{class:[
        'mr-2 type-defensive',
        _vm.newTag && _vm.newTag.type === 'offensive' ? 'active' : '' ],staticStyle:{"min-width":"70px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setNewTagField('type', 'offensive')}}},[_vm._v(" OFF ")]),_c('b-button',{class:[
        'type-defensive',
        _vm.newTag && _vm.newTag.type === 'transition+' ? 'active' : '' ],staticStyle:{"min-width":"70px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setNewTagField('type', 'transition+')}}},[_vm._v(" TR + ")])],1),_vm._l((_vm.teams),function(team){return _c('b-col',{key:team.id,attrs:{"cols":"1"}},[_c('team-dropdown',{attrs:{"team":team}})],1)}),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{class:[_vm.isRecording && 'active', 'w-100 rec-button'],attrs:{"variant":"primary"},on:{"click":_vm.toggleRecording}},[_c('b-icon',{attrs:{"icon":"circle-fill"}}),_vm._v(" REC ")],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{staticClass:"w-100 playback-light",attrs:{"variant":"primary"},on:{"click":_vm.rewind}},[_vm._v("-10 sec")])],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{staticClass:"w-100 playback-light",attrs:{"pressed":_vm.currentPlaybackRate === 0.5,"variant":"primary"},on:{"click":function($event){return _vm.changePlaybackRate(0.5)}}},[_vm._v("0.5x")])],1),_vm._l(([1, 2]),function(rate){return _c('b-col',{key:rate,attrs:{"cols":"1"}},[_c('b-button',{staticClass:"w-100 playback-dark",attrs:{"pressed":_vm.currentPlaybackRate === rate,"variant":"primary"},on:{"click":function($event){return _vm.changePlaybackRate(rate)}}},[_vm._v(_vm._s(rate)+"x")])],1)}),_c('b-col',{attrs:{"cols":"1"}},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"disabled":!(
              _vm.newTag &&
              _vm.newTag.id &&
              _vm.currentTime >= _vm.newTag.start &&
              _vm.currentTime <= _vm.newTag.end
            ),"variant":_vm.editor ? 'secondary' : 'primary',"title":"Screenshot"},on:{"click":function($event){return _vm.addEditor()}}},[_c('b-icon',{attrs:{"icon":"camera"}})],1)],1),_c('b-col',[_c('b-button',{attrs:{"variant":"danger","title":"Esci"},on:{"click":function($event){return _vm.exitFullScreen()}}},[_c('b-icon',{attrs:{"icon":"box-arrow-in-right"}})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }