<template>
  <div :class="['thumbnail-wrapper', tag.result]">
    <div class="thumbnail-top">
      <div class="action-number">{{ count }}</div>
      <div class="preferiti">
        <b-icon
          icon="heart"
          v-if="!tag.preferiti"
          @click.prevent="addPreferiti()"
          title="Aggiungi ai preferiti"
        ></b-icon>
        <b-icon
          icon="heart-fill"
          v-if="tag.preferiti"
          @click.prevent="removePreferiti()"
          title="Rimuovi dai preferiti"
        ></b-icon>
        <b-icon
          class="ml-2"
          icon="camera-reels-fill"
          v-if="tag.tactical"
          title="Tactical"
        ></b-icon>
      </div>
      <div class="action-player">{{ teamOrPlayerName }}</div>
      <div class="action-actions">
        <a :title="'Editor: ' + tag.editors.length" href="#" @click="viewEditor"
          ><b-icon
            icon="camera"
            :style="tag.editors.length > 0 ? 'color:yellow' : ''"
          ></b-icon>
        </a>
        <!--a href="javascript:;"
          ><i class="fa fa-futbol-o" aria-hidden="true"></i
        ></a>
        <a href="javascript:;"
          ><i class="fa fa-share-square-o" aria-hidden="true"></i
            ></a-->
        <a href="#" @click.prevent="deleteTag()"
          ><b-icon icon="trash-fill"></b-icon
        ></a>
      </div>
    </div>
    <b-img :src="thumbnail" center fluid-grow @click="seekTo" />
    <div class="thumbnail-time">{{ formatDuration(relativeStart) }}</div>
    <div class="thumbnail-bottom">
      <div class="action-wrapper">
        <div v-if="tag.type" :class="['action-type', `type-${classType}`]">
          {{ typeSubstring }}
        </div>
        <div class="field-section" v-if="tag.field_section">
          {{ tag.field_section }}
        </div>
      </div>
      <div class="skill-name">{{ skillNames }}</div>
      <div class="action-actions" v-if="auth.isUserEnable('video_down_clip')">
        <a
          href="#"
          @click.prevent="downloadTag()"
          v-if="!tag.download && !tag.clip"
          title="Richiedi download clip"
          ><b-icon icon="download"></b-icon
        ></a>
        <a
          v-if="tag.clip"
          :href="tag.clip_url"
          title="Download clip disponibile"
          download
          target="_blank"
        >
          <b-icon style="color: yellow" icon="camera-video-fill"></b-icon>
        </a>
        <b-icon
          v-if="tag.download"
          icon="arrow-clockwise"
          animation="spin"
        ></b-icon>
        <a href="#" v-b-modal="`info-tag-${tag.id}-modal`"
          ><b-icon icon="info-circle-fill"></b-icon
        ></a>
        <a href="#" @click.prevent="editDescription()"
          ><b-icon icon="pencil"></b-icon
        ></a>
      </div>
      <div v-else class="action-actions">
        <a href="#" v-b-modal="`info-tag-${tag.id}-modal`"
          ><b-icon icon="info-circle-fill"></b-icon
        ></a>
        <a href="#" @click.prevent="editDescription()"
          ><b-icon icon="pencil"></b-icon
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { mapState, mapGetters } from "vuex";
import { minBy, filter } from "lodash";
import tagMixin from "@/mixins/tags.js";

import Auth from "@/lib/auth.js";
import missing from "@/assets/images/missing.jpg";

export default {
  name: "VideoTag",
  data() {
    return {
      timer: false,
      timerDownload: false,
      missing,
    };
  },
  props: ["tag", "count"],
  mixins: [tagMixin],

  created: function () {
    if (!this.tag.snapshot_url) {
      this.$interval = setInterval(() => {
        this.loadThumbnail();
      }, 5000);
      this.timer = true;
    }
    if (this.tag.download) {
      this.$intervalDownload = setInterval(() => {
        this.loadClip();
      }, 5000);
      this.timerDownload = true;
    }
  },

  computed: {
    ...mapState("videotag", {
      data: "data",
    }),
    ...mapGetters("videotag", ["videoBookmarks"]),

    typeSubstring() {
      if (this.tag.type == "offensive" || this.tag.type == "defensive") {
        return this.tag.type.substring(0, 3);
      } else if (this.tag.type == "transition+") {
        return "tr+";
      } else if (this.tag.type == "transition-") {
        return "tr-";
      }
      return "";
    },

    classType() {
      if (this.tag.type == "offensive" || this.tag.type == "defensive") {
        return this.tag.type;
      } else if (this.tag.type == "transition+") {
        return "tr_plus";
      } else if (this.tag.type == "transition-") {
        return "tr_minus";
      }
      return "";
    },

    relativeStart() {
      if (this.videoBookmarks.length === 0) return this.tag.start;

      const filteredBookmarks = filter(
        this.videoBookmarks,
        (bookmark) => Number(bookmark.start) < Number(this.tag.start)
      );

      if (!filteredBookmarks || filteredBookmarks.length === 0)
        return this.tag.start;

      const closestBookmark = minBy(filteredBookmarks, (bookmark) =>
        Math.abs(Number(this.tag.start) - Number(bookmark.start))
      );
      return Math.abs(
        closestBookmark.time_match +
          (Number(this.tag.start) - Number(closestBookmark.start))
      );
    },
    thumbnail() {
      if (this.tag.snapshot_url != null && this.tag.snapshot_url != "") {
        return this.tag.snapshot_url;
      }
      return this.missing;
    },
    auth: function () {
      return Auth;
    },
  },

  watch: {
    tag: function () {
      if (this.tag.snapshot_url != null && this.tag.snapshot_url != "") {
        clearInterval(this.$interval);
        this.timer = false;
      } else {
        if (!this.timer) {
          this.$interval = setInterval(() => {
            this.loadThumbnail();
          }, 5000);
          this.timer = true;
        }
      }
    },

    "tag.download": function () {
      if (!this.tag.download) {
        clearInterval(this.$intervalDownload);
        this.timerDownload = false;
      } else {
        if (!this.timerDownload) {
          this.$intervalDownload = setInterval(() => {
            this.loadClip();
          }, 5000);
          this.timerDownload = true;
        }
      }
    },
  },

  methods: {
    seekTo() {
      this.$root.$emit("seekTo", Number(this.tag.start));
      this.$store.commit("videotag/setEditorShown", null);
      this.$store.commit("videotag/editTag", this.tag);
      this.$root.$emit("setTimelineWindow", Number(this.tag.start));
      if (this.data.video && this.data.video.tactical != this.tag.tactical) {
        this.$store.commit("videotag/toggleVideo");
      }
    },

    deleteTag() {
      this.confirm("Sei sicuro di voler eliminare il tag?").then((result) => {
        if (result.value) {
          this.$store.dispatch("videotag/deleteTag", this.tag);
          clearInterval(this.$interval);
          clearInterval(this.$intervalDownload);
          this.timer = false;
          this.timerDownload = false;
        }
      });
    },
    downloadTag() {
      /*  window.open(
        this.$store.state.apiEndPoint +
          "/videotag/download/clip/" +
          this.tag.id +
          "/" +
          Auth.user.id +
          "/" +
          Auth.user.api_token
          ); */
      this.$store.dispatch("videotag/downloadTag", this.tag.id);
      let myTag = this.tag;
      myTag.download = 1;
      this.$store.commit("videotag/replaceTag", myTag);
      //  this.tag.download = 1;
    },

    editDescription() {
      swal
        .fire({
          title: "Modifica la descrizione",
          input: "textarea",
          showCancelButton: true,
          allowOutsideClick: false,
          inputValue: this.tag.description || "",
        })
        .then((result) => {
          if (result.value) {
            this.$store.dispatch("videotag/updateTag", {
              tag: this.tag,
              data: { description: result.value },
            });
          }
        })
        .catch(() => {
          /* Do nothing */
        });
    },
    addPreferiti() {
      this.$store.dispatch("videotag/updateTag", {
        tag: this.tag,
        data: { preferiti: 1 },
      });
    },
    removePreferiti() {
      this.$store.dispatch("videotag/updateTag", {
        tag: this.tag,
        data: { preferiti: 0 },
      });
    },
    loadThumbnail() {
      this.$http
        .get("/videotag/thumbnail/base64/" + this.tag.id)
        .then((response) => {
          // this.tag.snapshot_url = response.data;
          let myTag = this.tag;
          myTag.snapshot_url = response.data;
          this.$store.commit("videotag/replaceTag", myTag);
        });
    },
    loadClip() {
      this.$http.get("/tag/" + this.tag.id).then((response) => {
        var tag = response.data;
        if (tag.clip) {
          //  this.tag.download = tag.download;
          //  this.tag.clip = tag.clip;
          //  this.tag.clip_url = tag.clip_url;
          let myTag = this.tag;
          myTag.download = tag.download;
          myTag.clip = tag.clip;
          myTag.clip_url = tag.clip_url;

          this.$store.commit("videotag/replaceTag", myTag);
        }
      });
    },
    viewEditor() {
      this.seekTo();
      this.$store.commit("videotag/enableFullScreen");
    },
  },
  beforeDestroy() {
    clearInterval(this.$interval);
    clearInterval(this.$intervalDownload);
    this.timer = false;
    this.timerDownload = false;
  },
};
</script>

<style lang="scss" scoped>
$background-default: rgba(0, 0, 0, 0.7);
$background-negative: rgba(166, 15, 33, 0.7);
$background-positive: rgba(55, 128, 70, 0.7);

$default-padding: 5px;

.thumbnail-wrapper {
  border-radius: 5px;
  position: relative;
  color: white;
  /*    font-size: 12px; */
  font-weight: 700;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    border-radius: 5px;
    cursor: pointer;
  }

  a,
  a:hover {
    color: white;
  }

  .fa {
    font-size: 16px;
  }

  .thumbnail-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: $background-default;
  }

  .thumbnail-time {
    border-radius: 5px;
    position: absolute;
    top: 43%;
    left: 10px;
    background-color: white;
    padding: $default-padding 10px;
    color: #212529;
    font-size: 12px;
  }

  .thumbnail-bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $background-default;
  }

  .action-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }

  .action-player,
  .skill-name {
    position: absolute;
    text-align: center;
    white-space: nowrap;
    width: 40%;
    left: 30%;
    overflow: hidden;
    font-size: 12px;
    /*   text-overflow: ellipsis; */
  }

  .action-number {
    background-color: black;
    border-radius: 5px;
    padding: 5px 7px;
    z-index: 1000;
    font-size: 0.8rem;
  }

  .preferiti {
    position: absolute;
    left: 40px;
    cursor: pointer;
    /*   text-overflow: ellipsis; */
  }

  .action-actions {
    z-index: 1000;

    a {
      background: transparent !important;
      color: #ffffff !important;
      padding: $default-padding;

      .thumbnail-close {
        font-size: 20px;
      }
    }
  }

  .action-type {
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    margin-right: 5px;
    z-index: 1000;

    &.type-offensive {
      background-color: #d3b826;
    }

    &.type-tr_plus {
      background-color: #d3b826;
    }

    &.type-defensive {
      background-color: #d3800b;
    }

    &.type-tr_minus {
      background-color: #d3800b;
    }
  }

  .field-section {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #a2a321;
    z-index: 1000;
    font-size: 12px;
  }

  &.negative {
    .thumbnail-top,
    .thumbnail-bottom {
      background-color: $background-negative;
    }
  }

  &.positive {
    .thumbnail-top,
    .thumbnail-bottom {
      background-color: $background-positive;
    }
  }
}
</style>
