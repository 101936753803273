<template>
  <b-card no-body>
    <b-tabs
      card
      :class="[
        'skill-tabs',
        goalkeeperMode ? 'goalkeeper-tabs' : 'player-tabs',
      ]"
    >
      <b-tab
        v-for="(category, i) in categories"
        :key="i"
        :title="category.name"
        :active="i === 0"
      >
        <skills :skills="category.skills" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Skills from "./Skills.vue";

export default {
  name: "SkillTabs",
  components: { Skills },
  computed: {
    ...mapGetters("videotag", ["categories"]),
    ...mapState("videotag", ["goalkeeperMode"]),
  },
};
</script>
