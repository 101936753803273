<template>
  <b-row class="mt-2">
    <b-col class="px-1">
      <b-card v-if="!tag.saving && !tag.saved && !tag.error">
        <div>{{ title }}</div>
        <div class="card-text">
          <b-list-group class="list-left">
            <b-list-group-item
              v-if="tag.start || tag.start == 0"
              class="summary-time"
            >
              <strong class="mr-1">Inizio:</strong>
              <b-form-input v-model="form.timeStart" size="sm" class="py-0" />
            </b-list-group-item>
            <b-list-group-item v-if="tag.result">
              <strong>Risultato:</strong> {{ tag.result }}
            </b-list-group-item>
            <b-list-group-item v-if="tag.team">
              <strong>Squadra:</strong> {{ teamName }}
            </b-list-group-item>
            <b-list-group-item v-if="tag.player">
              <strong>Giocatore:</strong> {{ playerName }}
            </b-list-group-item>
            <b-list-group-item v-if="tag.coach">
              <strong>Allenatore:</strong> {{ coachName }}
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="list-center">
            <b-list-group-item v-if="tag.end" class="summary-time">
              <strong class="mr-1">Fine:</strong>
              <b-form-input v-model="form.timeEnd" size="sm" class="py-0" />
            </b-list-group-item>
            <b-list-group-item v-if="fieldSection">
              <strong>Sezione campo:</strong> {{ fieldSection }}
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="list-right">
            <b-list-group-item v-if="tag.skills">
              <strong>Skill:</strong> {{ skillNames }}
            </b-list-group-item>
            <b-list-group-item v-if="tag.type">
              <strong>Tipo:</strong> {{ tag.type }}
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-row>
          <b-col v-if="tag.editing">
            <b-button variant="success" class="w-100" @click="update()">
              Aggiorna
            </b-button>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="cancel()" class="w-100"
              >Cancella</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import tagMixin from "@/mixins/tags.js";

export default {
  name: "TagSummary",
  mixins: [tagMixin],
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        timeStart: null,
        timeEnd: null,
      },
    };
  },

  computed: {
    ...mapGetters("videotag", ["tagById"]),
    title() {
      return this.tag.editing ? "Modifica Tag" : "Nuovo Tag";
    },

    start() {
      return this.formatDuration(_.get(this.tag, "start", 0));
    },

    end() {
      return this.formatDuration(_.get(this.tag, "end", 0));
    },

    fieldSection() {
      return this.tag.fieldSection || this.tag.field_section;
    },

    skillNames() {
      return this.tag.skills
        ? this.tag.skills.map((s) => s.name).join(", ")
        : "";
    },

    teamName() {
      return _.get(this.tag, "team.name");
    },

    playerName() {
      return _.get(this.tag, "player.name");
    },

    coachName() {
      return _.get(this.tag, "coach.name");
    },

    savingOrSavedText() {
      return this.tag.saving ? "Salvo il tag..." : "Tag salvato!";
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel", this.tag);
    },

    update() {
      this.$emit("update", this.tag);
    },

    stringToSeconds(str) {
      const tokens = str.split(":");

      if (tokens.length === 3) {
        return (
          Number(tokens[0]) * 3600 + Number(tokens[1]) * 60 + Number(tokens[2])
        );
      } else if (tokens.length === 2) {
        return Number(tokens[0]) * 60 + Number(tokens[1]);
      } else if (tokens.length === 1) {
        return Number(tokens[0]);
      }

      return 0;
    },

    secondsToString(sec) {
      let sum = Number(sec);
      let hours = "";
      let minutes = "";
      let seconds = "";

      if (sum >= 3600) {
        hours = sum / 3600;
        sum -= hours * 3600;
        hours += ":";
      }

      if (sum >= 60) {
        minutes = sum / 60;
        sum -= minutes * 60;
        minutes += ":";
      }

      return hours + minutes + sum.toString();
    },
  },

  created() {
    this.form.timeStart = this.start;
    this.form.timeEnd = this.end;
  },

  watch: {
    "form.timeStart"() {
      this.$store.commit("videotag/updateTagField", {
        key: "start",
        value: this.stringToSeconds(this.form.timeStart),
      });
    },
    "form.timeEnd"() {
      this.$store.commit("videotag/updateTagField", {
        key: "end",
        value: this.stringToSeconds(this.form.timeEnd),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0.5rem;
}

.list-group-item {
  padding: 0.5rem 0.75rem;
}

.card-text {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.list-left {
  flex: 1;

  .list-group-item:first-child {
    border-top-right-radius: 0;
  }

  .list-group-item:last-child {
    border-bottom-right-radius: 0;
  }
}

.list-right {
  flex: 1;

  .list-group-item:first-child {
    border-top-left-radius: 0;
  }

  .list-group-item:last-child {
    border-bottom-left-radius: 0;
  }
}

.list-center {
  flex: 1;

  .list-group-item:first-child {
    border-top-left-radius: 0;
  }

  .list-group-item:last-child {
    border-bottom-left-radius: 0;
  }
}

.summary-time {
  display: inline-flex;
  align-items: center;
}
</style>
