<template>
  <div id="container" class="svg-container">
    <svg :id="'avatar' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      default: "0",
    },
    avatar: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
  },

  data() {
    return {
      svg: null,
    };
  },

  computed: {},

  mounted() {
    this.plotAvatar();
  },

  methods: {
    plotAvatar() {
      const svg = d3
        .select("#avatar" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${this.width} ${this.height}`)
        .classed("svg-content", true);
      //   .attr("width", this.width)
      //   .attr("height", this.height);

      this.svg = svg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.width / 2 + "," + this.height / 2 + ")"
        );

      this.svg
        .append("circle")
        .attr("fill", "#EEEEEE")
        .attr("cy", 15)
        .attr("r", 45);

      const defs = this.svg.append("defs").attr("id", "imgdefs" + this.id);

      const catpattern = defs
        .append("pattern")
        .attr("id", "catpattern" + this.id)
        .attr("height", 1)
        .attr("width", 1)
        .attr("x", "0")
        .attr("y", "0")
        .attr("fill", "#EEEEEE");

      catpattern
        .append("image")
        .attr("x", -4)
        .attr("y", 20)
        .attr("height", 120)
        .attr("width", 120)
        .attr("xlink:href", this.avatar);

      this.svg
        .append("ellipse")
        .attr("rx", 55)
        .attr("ry", 70)
        .attr("cx", 0)
        .attr("cy", -10)
        .attr("fill", "url(#catpattern" + this.id + ")");
    },
  },
};
</script>
