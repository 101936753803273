<template>
  <div style="display: inline-block" class="w-100">
    <b-button
      variant="primary"
      class="mr-2 w-100"
      v-b-modal.settings-modal
      title="Impostazioni"
    >
      Impostazioni
    </b-button>

    <b-modal
      id="settings-modal"
      centered
      title="Impostazioni"
      size="lg"
      v-model="modalShow"
    >
      <b-row>
        <b-col cols="8" md="3" class="mb-2">
          <b-form-select v-model="settingSelectValue" :options="settingOptions">
            <template slot="first">
              <option value="">Seleziona un'impostazione</option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2" class="mb-2">
          <b-form-group>
            <b-button class="form" variant="success" @click="selectSetting()"
              >Carica</b-button
            >
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mb-2">
          <b-form-group>
            <b-button class="form" variant="primary" @click="deleteSetting()"
              >Elimina</b-button
            >
          </b-form-group>
        </b-col>

        <b-col cols="10" md="3" class="mb-2">
          <b-form-input
            v-model="newSetting"
            placeholder="Nome impostazione"
          ></b-form-input>
        </b-col>
        <b-col cols="2" class="mb-2">
          <b-form-group>
            <b-button class="form" variant="success" @click="createSetting()"
              >Crea</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>

      <template v-if="!!activeSetting">
        <b-row class="mb-2">
          <b-col cols="10">
            <b-form-input v-model="newCategory" placeholder="Nome categoria" />
          </b-col>
          <b-col cols="2">
            <b-button class="form" variant="success" @click="createCategory()"
              >Crea</b-button
            >
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="4">
            <b-form-select
              v-model="newSkill.category"
              :options="selectableCategories"
            >
              <template slot="first">
                <option :value="null">Categoria</option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="6">
            <b-form-select v-model="newSkill.skill">
              <template slot="first">
                <option :value="null">Skill</option>
              </template>

              <optgroup
                v-for="category in categories"
                :key="category.id"
                :label="category.name"
              >
                <template v-for="skill in category.skills">
                  <option
                    :value="skill.children ? null : skill"
                    :key="skill.id"
                    :disabled="
                      skill.children || selectedSkillIds.includes(skill.id)
                    "
                  >
                    {{ skill.name }}
                  </option>

                  <template v-for="subskill in skill.children">
                    <option
                      :value="subskill.children ? null : subskill"
                      :key="subskill.id"
                      :disabled="
                        subskill.children ||
                        selectedSkillIds.includes(subskill.id)
                      "
                    >
                      - {{ subskill.name }}
                    </option>

                    <template v-for="subsubskill in subskill.children">
                      <option
                        :value="subsubskill.children ? null : subsubskill"
                        :key="subsubskill.id"
                        :disabled="
                          subsubskill.children ||
                          selectedSkillIds.includes(subsubskill.id)
                        "
                      >
                        - - {{ subsubskill.name }}
                      </option>
                    </template>
                  </template>
                </template>
              </optgroup>
            </b-form-select>
          </b-col>
          <b-col cols="2">
            <b-button
              class="form"
              variant="success"
              @click.prevent="createSkill()"
              >Aggiungi</b-button
            >
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h4><strong>Azioni</strong></h4>
            <b-list-group>
              <b-list-group-item
                v-for="(skillCategory, c) in activeSetting.skill_categories"
                :key="`category-${c}`"
              >
                <div class="d-flex justify-content-between">
                  <strong>{{ skillCategory.name }}</strong>
                  <a href="#" @click.prevent="deleteCategory(c)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>

                <b-list-group v-if="skillCategory.skills" class="mt-2">
                  <b-list-group-item
                    v-for="(skill, i) in skillCategory.skills"
                    :key="`skill-${i}`"
                  >
                    <div class="d-flex justify-content-between">
                      {{ skill.name }}
                      <a href="#" @click.prevent="deleteSkill(c, i)">
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>

                    <b-list-group v-if="skill.children" class="mt-2">
                      <b-list-group-item
                        v-for="(child, j) in skill.children"
                        :key="`child-${j}`"
                      >
                        <div class="d-flex justify-content-between">
                          {{ child.name }}
                          <a href="#" @click.prevent="deleteSubskill(c, i, j)">
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </a>
                        </div>

                        <b-list-group v-if="child.children" class="mt-2">
                          <b-list-group-item
                            v-for="(subchild, x) in child.children"
                            :key="`subchild-${x}`"
                          >
                            <div class="d-flex justify-content-between">
                              {{ subchild.name }}
                              <a
                                href="#"
                                @click.prevent="deleteSubsubskill(c, i, j, x)"
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </a>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-list-group-item>
                    </b-list-group>
                  </b-list-group-item>
                </b-list-group>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button variant="success" class="form" @click="saveSetting()"
              >Salva</b-button
            >
          </b-col>
        </b-row>
      </template>

      <div slot="modal-footer">
        <b-button
          size="sm"
          class="float-right ml-2"
          variant="primary"
          @click="restoreDefaults()"
        >
          Ripristina azioni di default
        </b-button>
        <b-button
          size="sm"
          class="float-right ml-2"
          variant="secondary"
          @click="modalShow = false"
        >
          Chiudi
        </b-button>
        <b-button
          size="sm"
          class="float-right"
          variant="success"
          @click="applySetting()"
        >
          Applica impostazioni correnti
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { pick, findIndex, reduce } from "lodash";
import swal from "sweetalert2";

export default {
  name: "Settings",

  data() {
    return {
      editing: false,
      modalShow: false,
      settingSelectValue: "",
      newSetting: "",
      activeSetting: null,
      newCategory: "",
      newSkill: {
        category: null,
        skill: null,
      },
    };
  },

  computed: {
    ...mapGetters("videotag", [
      "settings",
      "settingById",
      "childSkills",
      "categories",
    ]),
    ...mapState("videotag", ["selectedSetting"]),

    settingOptions() {
      return this.settings.map((setting) => ({
        value: setting.id,
        text: setting.name,
      }));
    },

    selectableCategories() {
      if (!this.activeSetting || !this.activeSetting.skill_categories)
        return [];

      return this.activeSetting.skill_categories.map((category, index) => ({
        value: index,
        text: category.name,
      }));
    },

    selectableSkills() {
      return this.childSkills.map((skill) => ({
        value: skill,
        text: skill.name,
      }));
    },

    selectedSkillIds() {
      if (
        !this.activeSetting ||
        !Array.isArray(this.activeSetting.skill_categories)
      )
        return [];

      return reduce(
        this.activeSetting.skill_categories,
        (result, value) =>
          result.concat(
            reduce(
              value.skills,
              (skillResult, skillValue) => {
                skillResult.push(skillValue.id);
                if (!Array.isArray(skillValue.children)) {
                  return skillResult;
                }

                return skillResult.concat(
                  reduce(
                    skillValue.children,
                    (subskillResult, subskillValue) => {
                      subskillResult.push(subskillValue.id);
                      if (!Array.isArray(subskillValue.children)) {
                        return subskillResult;
                      }

                      return subskillResult.concat(
                        reduce(
                          subskillValue.children,
                          (subsubskillResult, subsubskillValue) => {
                            subsubskillResult.push(subsubskillValue.id);
                            return subsubskillResult;
                          },
                          []
                        )
                      );
                    },
                    []
                  )
                );
              },
              []
            )
          ),
        []
      );
    },
  },

  methods: {
    selectSetting() {
      if (this.settingSelectValue === "") {
        swal({
          type: "error",
          text: "Per favore, selezionare un'impostazione",
        });
        return;
      }

      this.activeSetting = Object.assign(
        {},
        this.settingById(Number(this.settingSelectValue))
      );
    },

    skillTree(selectedSkill) {
      const result = selectedSkill;

      this.categories.forEach((category) => {
        category.skills.forEach((skill) => {
          if (Array.isArray(skill.children)) {
            skill.children.forEach((subskill) => {
              if (Array.isArray(subskill.children)) {
                subskill.children.forEach((subsubskill) => {
                  if (subsubskill.id === result.id) {
                    result.parent = subskill;
                    result.grandparent = skill;
                  }
                });
              } else if (subskill.id === result.id) {
                result.parent = skill;
              }
            });
          }
        });
      });

      return result;
    },

    createSetting() {
      if (this.newSetting === "") {
        swal({ type: "error", text: "Per favore, immettere un nome valido" });
        return;
      }

      this.$store.dispatch("createSetting", this.newSetting);
      this.newSetting = "";
    },

    deleteSetting() {
      if (this.settingSelectValue === "") {
        swal({
          type: "error",
          text: "Per favore, selezionare un'impostazione",
        });
        return;
      }

      this.$store.dispatch("deleteSetting", Number(this.settingSelectValue));

      if (
        this.activeSetting &&
        this.activeSetting.id === Number(this.settingSelectValue)
      ) {
        this.activeSetting = null;
      }
      this.settingSelectValue = "";
    },

    createCategory() {
      if (this.newCategory === "") {
        swal({ type: "error", text: "Per favore, immettere un nome valido" });
        return;
      }

      if (!this.activeSetting.skill_categories)
        this.$set(this.activeSetting, "skill_categories", []);

      this.activeSetting.skill_categories.push({ name: this.newCategory });
      this.newCategory = "";
      if (!this.editing) this.editing = true;
    },

    deleteCategory(index) {
      if (
        !this.activeSetting ||
        !Array.isArray(this.activeSetting.skill_categories) ||
        !this.activeSetting.skill_categories[index]
      )
        return;

      this.$set(this.activeSetting.skill_categories[index], "delete", true);
      if (!this.editing) this.editing = true;
    },

    createSkill() {
      if (
        this.newSkill.category === null ||
        !this.activeSetting.skill_categories[this.newSkill.category]
      ) {
        swal({
          type: "error",
          text: "Per favore, immettere una categoria valida",
        });
        return;
      }

      if (
        !this.newSkill.skill ||
        this.selectedSkillIds.includes(this.newSkill.skill.id)
      ) {
        swal({ type: "error", text: "Per favore, immettere una skill valida" });
        return;
      }

      if (!this.activeSetting.skill_categories[this.newSkill.category].skills) {
        this.activeSetting.skill_categories[this.newSkill.category].skills = [];
      }

      const skillTree = this.skillTree(this.newSkill.skill);
      let grandparentIndex = -1;
      let parentIndex = -1;

      if (!skillTree.parent && !skillTree.grandparent) {
        this.activeSetting.skill_categories[this.newSkill.category].skills.push(
          pick(skillTree, ["id", "name"])
        );
      } else if (skillTree.grandparent && skillTree.parent) {
        grandparentIndex = findIndex(
          this.activeSetting.skill_categories[this.newSkill.category].skills,
          (skill) => skill.id === skillTree.grandparent.id
        );

        if (grandparentIndex === -1) {
          const grandparent = {
            ...pick(skillTree.grandparent, ["id", "name"]),
            children: [],
          };

          this.activeSetting.skill_categories[
            this.newSkill.category
          ].skills.push(grandparent);
          grandparentIndex =
            this.activeSetting.skill_categories[this.newSkill.category].skills
              .length - 1;
        }

        parentIndex = findIndex(
          this.activeSetting.skill_categories[this.newSkill.category].skills[
            grandparentIndex
          ].children,
          (skill) => skill.id === skillTree.parent.id
        );

        if (parentIndex === -1) {
          const parent = {
            ...pick(skillTree.parent, ["id", "name"]),
            children: [],
          };

          this.activeSetting.skill_categories[this.newSkill.category].skills[
            grandparentIndex
          ].children.push(parent);
          parentIndex =
            this.activeSetting.skill_categories[this.newSkill.category].skills[
              grandparentIndex
            ].children.length - 1;
        }

        this.activeSetting.skill_categories[this.newSkill.category].skills[
          grandparentIndex
        ].children[parentIndex].children.push(pick(skillTree, ["id", "name"]));
      } else if (!skillTree.grandparent && skillTree.parent) {
        parentIndex = findIndex(
          this.activeSetting.skill_categories[this.newSkill.category].skills,
          (skill) => skill.id === skillTree.parent.id
        );

        if (parentIndex === -1) {
          const parent = {
            ...pick(skillTree.parent, ["id", "name"]),
            children: [],
          };

          this.activeSetting.skill_categories[
            this.newSkill.category
          ].skills.push(parent);
          parentIndex =
            this.activeSetting.skill_categories[this.newSkill.category].skills
              .length - 1;
        }

        this.activeSetting.skill_categories[this.newSkill.category].skills[
          parentIndex
        ].children.push(pick(skillTree, ["id", "name"]));
      }

      this.activeSetting = { ...this.activeSetting };

      if (!this.editing) this.editing = true;
    },

    deleteSkill(categoryIndex, skillIndex) {
      if (
        !this.activeSetting ||
        !Array.isArray(this.activeSetting.skill_categories) ||
        !this.activeSetting.skill_categories[categoryIndex] ||
        !this.activeSetting.skill_categories[categoryIndex].skills ||
        !this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
      )
        return;

      this.activeSetting.skill_categories[categoryIndex].skills.splice(
        skillIndex,
        1
      );
      if (!this.editing) this.editing = true;

      this.activeSetting = { ...this.activeSetting };
    },

    deleteSubskill(categoryIndex, skillIndex, subskillIndex) {
      if (
        !this.activeSetting ||
        !Array.isArray(this.activeSetting.skill_categories) ||
        !this.activeSetting.skill_categories[categoryIndex] ||
        !this.activeSetting.skill_categories[categoryIndex].skills ||
        !this.activeSetting.skill_categories[categoryIndex].skills[
          skillIndex
        ] ||
        !this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children ||
        !this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children[subskillIndex]
      )
        return;

      this.activeSetting.skill_categories[categoryIndex].skills[
        skillIndex
      ].children.splice(subskillIndex, 1);

      if (
        this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children.length === 0
      ) {
        this.deleteSkill(categoryIndex, skillIndex);
      }

      if (!this.editing) this.editing = true;
      this.activeSetting = { ...this.activeSetting };
    },

    deleteSubsubskill(
      categoryIndex,
      skillIndex,
      subskillIndex,
      subsubSkillIndex
    ) {
      if (
        !this.activeSetting ||
        !Array.isArray(this.activeSetting.skill_categories) ||
        !this.activeSetting.skill_categories[categoryIndex] ||
        !this.activeSetting.skill_categories[categoryIndex].skills ||
        !this.activeSetting.skill_categories[categoryIndex].skills[
          skillIndex
        ] ||
        !this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children ||
        !this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children[subskillIndex] ||
        !this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children[subskillIndex].children[subsubSkillIndex]
      )
        return;

      this.activeSetting.skill_categories[categoryIndex].skills[
        skillIndex
      ].children[subskillIndex].children.splice(subsubSkillIndex, 1);

      if (
        this.activeSetting.skill_categories[categoryIndex].skills[skillIndex]
          .children[subskillIndex].children.length === 0
      ) {
        this.deleteSubskill(categoryIndex, skillIndex, subskillIndex);
      }

      if (!this.editing) this.editing = true;
      this.activeSetting = { ...this.activeSetting };
    },

    saveSetting() {
      if (!this.activeSetting || !this.activeSetting.id) return;

      this.$store
        .dispatch("updateSetting", this.activeSetting)
        .then((data) => {
          this.activeSetting = data;
          this.editing = false;
        })
        .catch(() =>
          this.$store.commit("msgPush", {
            status: 0,
            msg: "Ops! Qualcosa è andato storto",
          })
        );
    },

    applySetting() {
      if (!this.activeSetting || !this.activeSetting.id) {
        swal({ type: "error", text: "Selezionare un'impostazione valida" });
        return;
      }

      if (this.editing) {
        swal({
          type: "error",
          text: "Salvare le modifiche prima di applicarle",
        });
        return;
      }

      this.$store.commit("videotag/applySetting", this.activeSetting.id);
    },

    restoreDefaults() {
      this.$store.commit("videotag/restoreDefaultSetting");
    },
  },
};
</script>
