<template>
  <b-col cols="4">
    <b-row style="flex-wrap: no-wrap">
      <b-col cols="5">
        <team-tabs :teams="teams" />
      </b-col>
      <b-col cols="7" class="pl-1">
        <skill-tabs />
      </b-col>
    </b-row>
    <b-row align-v="end" class="mt-2">
      <b-col cols="1" class="text-center">
        <b-button
          variant="primary"
          class="mb-2"
          style="height: 70px"
          @click="setTagField('result', 'positive')"
          :active="newTag && newTag.result === 'positive'"
          ><b-icon icon="plus"> "></b-icon></b-button
        >
        <b-button
          variant="danger"
          style="height: 70px"
          @click="setTagField('result', 'negative')"
          :active="newTag && newTag.result === 'negative'"
          ><b-icon
            icon="dash
            "
          ></b-icon
        ></b-button>
      </b-col>
      <b-col class="text-center pl-3 pr-1">
        <b-row>
          <b-col class="text-center pl-2"
            ><b-button
              style="background: #f7aa00"
              class="w-100"
              @click="setTagField('field_section', 1)"
              :active="newTag && Number(newTag.field_section) === 1"
              >Z 1</b-button
            ></b-col
          >
          <b-col class="text-center px-2"
            ><b-button
              style="background: #00b6e8"
              class="w-100"
              @click="setTagField('field_section', 2)"
              :active="newTag && Number(newTag.field_section) === 2"
              >Z 2</b-button
            ></b-col
          >
          <b-col class="text-center pr-2"
            ><b-button
              style="background: #7fbf86"
              class="w-100"
              @click="setTagField('field_section', 3)"
              :active="newTag && Number(newTag.field_section) === 3"
              >Z 3</b-button
            ></b-col
          >
        </b-row>
        <field />
      </b-col>
      <b-col cols="2" class="text-center pr-1">
        <b-button
          variant="warning"
          class="mb-1 w-100"
          @click="setTagField('type', 'defensive')"
          :active="newTag && newTag.type === 'defensive'"
          >DEF</b-button
        >
        <b-button
          variant="warning"
          class="mb-1 w-100"
          @click="setTagField('type', 'transition-')"
          :active="newTag && newTag.type === 'transition-'"
          >TR -</b-button
        >
        <b-button
          variant="warning"
          class="mb-1 w-100"
          @click="setTagField('type', 'offensive')"
          :active="newTag && newTag.type === 'offensive'"
          >OFF</b-button
        >
        <b-button
          variant="warning"
          class="w-100"
          @click="setTagField('type', 'transition+')"
          :active="newTag && newTag.type === 'transition+'"
          >TR +</b-button
        >
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapState } from "vuex";
import TeamTabs from "./TeamTabs.vue";
import SkillTabs from "./SkillTabs.vue";
import Field from "./Field.vue";

export default {
  name: "NewTag",
  props: ["teams"],
  components: {
    TeamTabs,
    SkillTabs,
    Field,
  },

  computed: mapState("videotag", ["newTag"]),

  methods: {
    setTagField(key, value) {
      this.$store.commit("videotag/updateTagField", { key, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.row .col,
.row [class*="col-"] {
  padding: 0;
}
</style>

<style lang="scss">
.tab-content {
  padding: 0px !important;
}

.skill-tabs .tab-content {
  padding-bottom: 2px !important;
}

.team-tabs .card-body {
  padding: 7px !important;
}

.skill-tabs .card-body {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.nav-tabs {
  //  display: inline-flex;
  //  width: 100%;

  .nav-item {
    white-space: nowrap;
    text-align: center;
    font-size: 0.8rem;

    a {
      overflow: hidden;
    }
  }
}

.skill-tabs.player-tabs .nav-tabs .nav-item {
  width: 33.3333%;
}

.skill-tabs.goalkeeper-tabs .nav-tabs .nav-item {
  width: 25%;
}

.team-tabs .nav-tabs .nav-item {
  width: 50%;
}
</style>
