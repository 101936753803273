<template>
  <b-button-group class="w-100">
    <b-button variant="primary" @click="seekTo()" style="flex: 1">{{
      bookmark.name
    }}</b-button>
    <b-button
      variant="primary"
      @click="deleteBookmark()"
      style="max-width: 50px"
      ><b-icon icon="trash"></b-icon
    ></b-button>
  </b-button-group>
</template>

<script>
export default {
  name: "Bookmark",
  props: {
    bookmark: {
      type: Object,
      required: true,
    },
  },

  methods: {
    seekTo() {
      this.$root.$emit("seekTo", this.bookmark.start);
    },

    deleteBookmark() {
      this.$store.dispatch("videotag/deleteBookmark", this.bookmark.id);
    },
  },
};
</script>
