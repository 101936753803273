<template>
  <div class="thumbnails" id="video-tags" v-if="!isTagsEmpy">
    <video-tag
      v-for="(tag, index) in sorted_tags"
      :key="tag.id"
      :tag="tag"
      :count="tags.length - index"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VideoTag from "./VideoTag.vue";

export default {
  name: "VideoTags",
  components: { VideoTag },
  computed: {
    ...mapGetters("videotag", ["tags"]),
    isTagsEmpy() {
      return !Array.isArray(this.tags) || this.tags.length === 0;
    },
    sorted_tags() {
      return this.tags.slice(0).sort((a, b) => {
        return b.start - a.start;
      });
    },
  },
};
</script>

<style lang="scss">
.thumbnails {
}
</style>
