var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{staticClass:"editor-bar",attrs:{"id":"editor-bar","width":"4.4em","title":"Sidebar","sidebar-class":"editor-bar","right":"","shadow":"","no-header-close":true,"no-header":true,"bg-variant":"dark-transparent"},model:{value:(_vm.editorMode),callback:function ($$v) {_vm.editorMode=$$v},expression:"editorMode"}},[_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"icon-editor",attrs:{"id":"popover-color","title":"Seleziona colore"}},[_c('b-icon',{staticStyle:{"color":"#ffffff"},attrs:{"icon":"brush","font-scale":"3"}})],1),_c('b-popover',{attrs:{"target":"popover-color","placement":"bottom"}},[_c('compact',{attrs:{"value":_vm.color,"palette":[
            'black',
            'white',
            'gray',
            'red',
            'blue',
            'green',
            'orange',
            'yellow',
            'purple',
            'navy',
            'aqua',
            'lime' ]},on:{"input":_vm.changeColor}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.activeObject === 'Text' ? 'icon-editor-active' : 'icon-editor',attrs:{"title":"Inserisci testo"},on:{"click":function($event){return _vm.clearAndSetActiveObject('Text')}}},[_c('icona1')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.drawingMode ? 'icon-editor-active' : 'icon-editor',attrs:{"title":"Disegno libero"},on:{"click":_vm.toggleDrawingMode}},[_c('icona2')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.activeObject === 'Line' ? 'icon-editor-active' : 'icon-editor',attrs:{"title":"Freccia"},on:{"click":function($event){return _vm.clearAndSetActiveObject('Line')}}},[_c('icona9')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.activeObject === 'DashedLine' ? 'icon-editor-active' : 'icon-editor',attrs:{"title":"Freccia tratteggiata"},on:{"click":function($event){return _vm.clearAndSetActiveObject('DashedLine')}}},[_c('icona3')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.activeObject === 'FlatLine' ? 'icon-editor-active' : 'icon-editor',attrs:{"title":"Linea"},on:{"click":function($event){return _vm.clearAndSetActiveObject('FlatLine')}}},[_c('icona4')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"icon-editor",attrs:{"id":"popover-ring"}},[_c('icona5')],1),_c('b-popover',{attrs:{"target":"popover-ring","placement":"top"}},[_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'ring_blue'
              ? 'p-2 mb-1 icon-editor-active'
              : 'p-2 mb-1 icon-editor',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clearAndSetActiveObject({ id: 'ring_blue', image_url: _vm.ringBlue })}}},[_c('b-img',{attrs:{"src":_vm.ringBlue,"width":"50"}})],1),_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'ring_red'
              ? 'p-2 mb-1 icon-editor-active'
              : 'p-2 mb-1 icon-editor',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clearAndSetActiveObject({ id: 'ring_red', image_url: _vm.ringRed })}}},[_c('b-img',{attrs:{"src":_vm.ringRed,"width":"50"}})],1),_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'ring_yellow'
              ? 'p-2 mb-1 icon-editor-active'
              : 'p-2 mb-1 icon-editor',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clearAndSetActiveObject({
              id: 'ring_yellow',
              image_url: _vm.ringYellow,
            })}}},[_c('b-img',{attrs:{"src":_vm.ringYellow,"width":"50"}})],1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.activeObject === 'roof' ? 'icon-editor-active' : 'icon-editor',attrs:{"title":"Seleziona area"},on:{"click":function($event){return _vm.clearAndSetActiveObject('roof')}}},[_c('icona6')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'cono'
            ? 'icon-editor-active'
            : 'icon-editor',attrs:{"title":"Seleziona cono"},on:{"click":function($event){return _vm.clearAndSetActiveObject({ id: 'cono', image_url: _vm.cono })}}},[_c('icona7')],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"icon-editor",attrs:{"id":"popover-path"}},[_c('icona8'),_c('b-popover',{attrs:{"target":"popover-path","placement":"top"}},[_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'path_ring_blue'
                ? 'p-2 mb-1 icon-editor-active'
                : 'p-2 mb-1 icon-editor',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clearAndSetActiveObject({
                id: 'path_ring_blue',
                image_url: _vm.ringBlue,
                is_path: true,
              })}}},[_c('b-img',{attrs:{"src":_vm.ringBlue,"width":"50"}})],1),_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'path_ring_red'
                ? 'p-2 mb-1 icon-editor-active'
                : 'p-2 mb-1 icon-editor',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clearAndSetActiveObject({
                id: 'path_ring_red',
                image_url: _vm.ringRed,
                is_path: true,
              })}}},[_c('b-img',{attrs:{"src":_vm.ringRed,"width":"50"}})],1),_c('div',{class:_vm.activeObject && _vm.activeObject.id === 'path_ring_yellow'
                ? 'p-2 mb-1 icon-editor-active'
                : 'p-2 mb-1 icon-editor',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clearAndSetActiveObject({
                id: 'path_ring_yellow',
                image_url: _vm.ringYellow,
                is_path: true,
              })}}},[_c('b-img',{attrs:{"src":_vm.ringYellow,"width":"50"}})],1)])],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"icon-editor",on:{"click":_vm.deleteCanvasObject}},[_c('b-icon',{staticStyle:{"color":"#ffffff"},attrs:{"icon":"trash","font-scale":"3"}})],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"icon-editor",on:{"click":_vm.saveEditor}},[_c('b-icon',{staticStyle:{"color":"#ffffff"},attrs:{"icon":"folder","font-scale":"3"}})],1)])],1),_c('b-row',[_c('b-col',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"icon-editor",on:{"click":_vm.chiudiEditor}},[_c('b-icon',{staticStyle:{"color":"#ffffff"},attrs:{"icon":"box-arrow-right","font-scale":"3"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }