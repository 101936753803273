<template>
  <b-row class="mt-2">
    <b-col class="px-1">
      <b-card no-body>
        <b-tabs
          card
          v-if="homeTeam && visitingTeam"
          @input="(i) => (index = i)"
        >
          <b-tab v-if="homeTeam" :title="homeTeam.name" active>
            <timeline
              v-if="video.start && video.end"
              :team="homeTeam"
              :tags="homeTags"
              :skills="skills"
            />
          </b-tab>

          <b-tab v-if="visitingTeam" :title="visitingTeam.name">
            <timeline
              v-if="video.start && video.end && (index === 1 || tabOpened)"
              :team="visitingTeam"
              :tags="visitingTags"
              :skills="skills"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import Timeline from "./Timeline.vue";

export default {
  name: "TimelineTabs",
  props: ["tags", "skills"],
  components: {
    Timeline,
  },

  data() {
    return {
      index: 0,
      // This is to check if the second tab has already been
      // opened. We need this because visjs can't position the items
      // properly when the tab is closed, so we create the component
      // only when the tab is open
      tabOpened: false,
    };
  },

  computed: {
    ...mapState("videotag", {
      video: (state) => state.data.video,
      homeTeam: (state) => _.get(state, "data.teams.home"),
      visitingTeam: (state) => _.get(state, "data.teams.visiting"),
    }),
    /**
     * Filters only the tags that belongs to the home team
     *
     * @return {Array}
     */
    homeTags() {
      return this.tags.filter((tag) => this.teamHasTag(this.homeTeam, tag));
    },
    /**
     * Filters only the tags that belongs to the visiting team
     *
     * @return {Array}
     */
    visitingTags() {
      return this.tags.filter((tag) => this.teamHasTag(this.visitingTeam, tag));
    },
  },

  methods: {
    /**
     * Checks if the specified team has the specified tag
     *
     * @param {Object} team
     * @param {Object} tag
     *
     * @return {boolean}
     */
    teamHasTag(team, tag) {
      if (!tag || !team || (team && !Array.isArray(team.tag_ids))) return false;

      return team.tag_ids.includes(tag.id);
    },
  },

  watch: {
    index(newVal) {
      if (newVal === 1) this.tabOpened = true;
    },
  },
};
</script>

<style lang="scss">
.tab-content {
  padding: 10px;
}
.skill-tabs .card-body {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
</style>
