<template>
  <div v-if="show_editors" style="position: absolute; z-index: 2; height: 85%">
    <b-button
      :title="editorsHidden ? 'Mostra editors' : 'Nascondi editors'"
      :variant="editorsHidden ? 'success' : 'danger'"
      size="sm"
      @click="toggleEditors"
      ><b-icon :icon="editorsHidden ? 'eye' : 'eye-slash'"></b-icon
    ></b-button>
    <div
      style="
        position: absolute;
        z-index: 2;
        padding-top: 10px;
        height: 85%;
        overflow-y: scroll;
      "
      id="editors-tag"
      v-if="!editorsHidden"
    >
      <b-row v-for="item in newTag.editors" :key="item.id" class="mb-2">
        <b-col>
          <tag-editor :tag_editor="item" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TagEditor from "./TagEditor.vue";
export default {
  data: function () {
    return {};
  },

  components: {
    TagEditor,
  },

  created: function () {},

  computed: {
    ...mapState("videotag", {
      fullScreen: "fullScreen",
      newTag: "newTag",
      editorsHidden: "editorsHidden",
    }),

    show_editors() {
      return this.fullScreen && this.newTag && this.newTag.editors.length > 0;
    },
  },
  methods: {
    toggleEditors() {
      this.$store.commit("videotag/toggleEditors");
    },
  },
  watch: {},
};
</script>
<style></style>
