<template>
  <div :id="teamId"></div>
</template>

<script>
import { isArray, merge, get } from "lodash";
import { mapState, mapGetters } from "vuex";
import vis from "vis";
import tagMixin from "@/mixins/tags.js";
import missing from "@/assets/images/missing.jpg";

export default {
  name: "Timeline",
  props: ["team", "tabId"],
  mixins: [tagMixin],

  data() {
    const self = this;

    return {
      teamId: `team-${this.team.id}-timeline`,
      count: 60,
      visOptions: {
        selectable: true,
        editable: {
          add: false,
          remove: true,
          updateGroup: false,
          updateTime: true,
          overrideItems: false,
        },
        orientation: { axis: "both", item: "bottom" },
        timeAxis: { scale: "second", step: 5 },
        margin: { item: { horizontal: 20, vertical: 20 } },
        showMajorLabels: false,
        format: {
          minorLabels: {
            millisecond: "",
            second: "mm:ss",
            minute: "mm:ss",
            hour: "HH:mm:ss",
            weekday: "",
            day: "",
            month: "",
            year: "",
          },
        },
        zoomMin: 5000, // 5 seconds
        zoomMax: 1000 * 60 * 10, // 10 minutes
        onRemove(item, callback) {
          self
            .confirm("Sei sicuro di voler eliminare il tag?")
            .then((result) => {
              if (result.value) {
                self.$store.dispatch(
                  "videotag/deleteTag",
                  self.tags.find((t) => t.id === item.id)
                );
              }
              callback(null);
            })
            .catch(() => {
              callback(null);
            });
        },
        onMoving: self.onItemMoving,
      },
      missing,
    };
  },

  computed: {
    ...mapState("videotag", {
      videoStart: (state) => state.data.video.start,
      videoEnd: (state) => state.data.video.end,
      currentTime: (state) => state.currentTime,
      video: (state) => state.data.video,
    }),
    ...mapGetters("videotag", ["tags", "skills", "tagById"]),
    /**
     * Builds the groups for the timeline based on the team
     * players and the team itself
     *
     * @return {Array}
     */
    groups() {
      if (!isArray(this.team.players)) return null;

      const arr = this.team.players.map((player) => ({
        id: `player-${player.id}`,
        content: player.name,
        order: parseInt(player.id, 10) + (this.team.coach ? 2 : 1),
      }));
      // Add the team and coach at the beginning of the array
      arr.unshift(
        {
          id: `team-${this.team.id}`,
          content: this.team.name,
          order: 1,
        },
        ...(this.team.coach
          ? [
              {
                id: `coach-${this.team.coach.id}`,
                content: this.team.coach.name + " (coach)",
                order: 2,
              },
            ]
          : [])
      );
      return arr;
    },

    /**
     * Builds the datasets for the timeline base on the tags
     *
     * @return {Array}
     */
    dataSets() {
      if (!isArray(this.tags)) return null;

      return this.tags.map((tag, index) => {
        const html = `
        <div class="timeline-item">
        <div class="timeline-item-image-wrapper">
        <img class="timeline-item-image" src="${
          tag.snapshot_url || this.missing
        }" height="50" width="80" />
        </div>
        <div class="timeline-item-index">
        ${index + 1}
        </div>
        <span class="timeline-item-text">${this.getTagSkills(tag)}</div>
        </div>
        `;

        return {
          id: tag.id,
          content: html,
          start: new Date(
            this.videoStart.getTime() + 1000 * parseInt(tag.start, 10)
          ),
          end: new Date(
            this.videoStart.getTime() + 1000 * parseInt(tag.end, 10)
          ),
          group: tag.team
            ? `team-${tag.team.id}`
            : tag.coach
            ? `coach-${tag.coach.id}`
            : `player-${tag.player.id}`,
          className: tag.result,
        };
      });
    },
  },

  methods: {
    initTimeline() {
      const options = merge(this.visOptions, {
        min: this.videoStart,
        max: this.videoEnd,
        start: this.videoStart,
        end: new Date(this.videoStart.getTime() + 1000 * 60),
      });

      this.$timeline = new vis.Timeline(
        this.$el,
        this.dataSets,
        this.groups,
        options
      );
      this.$timeline.addCustomTime(this.videoStart);
      this.$timeline.on("timechanged", this.onTimeChanged);
      this.$timeline.on("select", this.onItemSelected);
      this.$root.$on("setTimelineWindow", (val) => {
        const time = this.videoStart.getTime() + Number(val) * 1000;
        const start = time - 60000;
        const end = time + 60000;
        this.$timeline.setWindow(start, end);
      });
    },

    updateTime(newTime) {
      this.$timeline.setCustomTime(newTime);
    },

    onTimeChanged() {
      const timeDelta =
        Math.abs(this.$timeline.getCustomTime() - this.videoStart) / 1000.0;
      this.$store.commit("videotag/seekTo", timeDelta);
    },

    onItemSelected(data) {
      const tag = this.tagById(data.items[0]);
      this.$root.$emit("videotag/seekTo", {
        time: Number(tag.start),
        play: false,
      });
      this.$store.commit("videotag/setEditorShown", null);
      this.$store.commit("videotag/editTag", tag);
      if (this.video && this.video.tactical != tag.tactical) {
        this.$store.commit("videotag/toggleVideo");
      }
    },

    onItemMoving(item, callback) {
      if (item.start < item.end) {
        this.$store.commit("videotag/updateTagDuration", item);
        callback(item);
      } else {
        callback(null);
      }
    },

    getTagSkills(tag) {
      return tag.skills
        ? tag.skills.map((s) => s.name).join(", ")
        : "Azione generica";
    },
  },

  watch: {
    currentTime(newVal) {
      this.updateTime(new Date(this.videoStart.getTime() + 1000 * newVal));

      this.count -= 1;
      if (this.count === 0) {
        const timelineWindow = this.$timeline.getWindow();
        const currentTime = this.$timeline.getCustomTime().getTime();
        const delta = new Date(
          timelineWindow.start.getTime() +
            (timelineWindow.end - timelineWindow.start) / 2
        ).getTime();
        if (delta <= currentTime) {
          const currentTimeDelta = (currentTime - delta) / 2 + 1000;
          this.$timeline.setWindow(
            timelineWindow.start.getTime() + currentTimeDelta,
            timelineWindow.end.getTime() + currentTimeDelta
          );
        }
        this.count = 60;
      }
    },

    tags() {
      if (this.$timeline) {
        this.$timeline.setItems(this.dataSets);
      }
    },
  },

  mounted() {
    this.initTimeline();
  },

  beforeDestroy() {
    this.$root.$off("setTimelineWindow");
  },
};
</script>

<style lang="scss">
$background-negative: #a60f21;
$background-positive: #378046;

.vis-item {
  &.positive,
  &.negative {
    color: white;
  }

  &.positive {
    background-color: $background-positive;
    border-color: $background-positive;
  }

  &.negative {
    background-color: $background-negative;
    border-color: $background-negative;
  }
}

.vis-item-content {
  padding: 0 !important;
}

.timeline-item {
  display: flex;
  align-items: center;
  height: 50px;
}

.timeline-item-image-wrapper {
  height: 50px;
  width: 80px;
  margin-right: 5px;
  background-color: #333;

  img {
    opacity: 0.5;
  }
}

.timeline-item-index {
  width: 80px;
  height: 50px;
  margin-right: 5px;
  z-index: 1000;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
