<template>
  <div class="wrapper home videotag">
    <nav
      v-if="!sidebarHidden"
      id="sidebar"
      data-simplebar
      :key="'sb_' + refresh"
    >
      <video-tags />
    </nav>
    <tag-editors />
    <editor-bar />
    <b-container fluid>
      <b-row>
        <!-- Video container -->
        <editor v-if="editor" />
        <!--video-player v-if="data && data.video && !editor" :video="data.video"-->
        <video-player
          v-if="data && data.video"
          :video="data.video"
          v-show="!editor"
        >
          <template v-if="data && !fullScreen">
            <b-row>
              <b-col class="mt-2" v-if="false">
                <settings />
              </b-col>

              <!--b-col class="mt-2 px-1">
                <b-button
                  variant="primary"
                  class="mr-2 w-100"
                  @click="toggleEditor()"
                  :title="editorText"
                >
                  {{ editorText }}
                </b-button>
              </b-col-->
              <b-col
                class="mt-2 px-1"
                v-if="
                  data.video &&
                  data.video.wyscout_url &&
                  data.video.tactical_url
                "
              >
                <b-button
                  variant="primary"
                  class="mr-2 w-100"
                  @click="toggleVideo()"
                  :title="modeVideoText"
                  :disabled="newTag != null"
                >
                  <b-icon
                    :icon="
                      data.video && data.video.tactical
                        ? 'camera-video-fill'
                        : 'camera-reels-fill'
                    "
                  ></b-icon>
                  {{ modeVideoText }}
                </b-button>
              </b-col>

              <b-col class="mt-2 px-1">
                <b-button
                  variant="primary"
                  class="mr-2 w-100"
                  @click="toggleSidebar()"
                  :title="hideSidebarText"
                >
                  {{ hideSidebarText }}
                </b-button>
              </b-col>

              <b-col class="mt-2 px-1">
                <b-button
                  variant="primary"
                  class="mr-2 w-100"
                  @click="enableFullScreen()"
                  title="Fullscreen"
                >
                  Fullscreen
                </b-button>
              </b-col>

              <b-col class="mt-2 px-1" v-if="false">
                <export-tags />
              </b-col>

              <b-col class="mt-2 px-1">
                <b-button
                  :variant="tagListEnabled ? 'success' : 'primary'"
                  :class="['mr-2 w-100', tagListEnabled ? 'active' : '']"
                  @click="toggleTagList()"
                >
                  Tag list
                </b-button>
              </b-col>

              <b-col class="col-goal mt-2 px-1" v-if="goalVisible">
                <goal />
                <goal negative />
              </b-col>

              <b-col class="mt-2 px-1">
                <b-button
                  class="w-100"
                  variant="primary"
                  @click="switchMode"
                  :title="goalkeeperMode ? 'Player mode' : 'Goalkeeper mode'"
                >
                  {{ goalkeeperMode ? "Player mode" : "Gk mode" }}
                </b-button>
              </b-col>

              <b-col class="mt-2 px-1">
                <bookmarks v-if="data.bookmarks" />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="mt-2 px-1"
                v-for="bookmark in videoBookmarks"
                :key="bookmark.id"
              >
                <bookmark :bookmark="bookmark" />
              </b-col>
            </b-row>

            <b-row v-if="newTag" class="my-0">
              <b-col>
                <tag-summary
                  :tag="newTag"
                  @cancel="cancelNewTag()"
                  @update="updateTag()"
                />
              </b-col>
            </b-row>
          </template>
          <new-tag-full-screen v-else />
        </video-player>
        <!-- New tag -->
        <template v-if="!fullScreen">
          <new-tag
            v-if="data && data.teams"
            v-show="!tagListEnabled"
            :teams="data.teams"
          />
          <tag-filters v-if="data" v-show="tagListEnabled" />
        </template>
      </b-row>

      <!-- Timeline -->
      <timeline-tabs
        v-if="data && data.teams && data.tags && data.skills && !fullScreen"
        :teams="data.teams"
        :tags="data.tags"
      />
    </b-container>

    <template v-for="tag in tags">
      <share-tag :key="`share-${tag.id}`" :tag="tag" />
      <info-tag :key="`info-${tag.id}`" :tag="tag" />
    </template>

    <!--goal-modal />
    <goal-modal negative /-->
  </div>
</template>

<script>
import "simplebar";
import { mapState, mapGetters } from "vuex";
import VideoPlayer from "./video/VideoPlayer.vue";
import TimelineTabs from "./timeline/TimelineTabs.vue";
import VideoTags from "./videotags/VideoTags.vue";
import NewTag from "./newtag/NewTag.vue";
import Bookmarks from "./bookmarks/Bookmarks.vue";
import TagFilters from "./tagfilters/TagFilters.vue";
import ExportTags from "./export/ExportTags.vue";
import ShareTag from "./modals/ShareTag.vue";
import InfoTag from "./modals/InfoTag.vue";
import Settings from "./Settings.vue";
import NewTagFullScreen from "./newtag/NewTagFullscreen.vue";
import Goal from "./goal/Goal.vue";
//import GoalModal from "./goal/GoalModal.vue";
import Bookmark from "./bookmarks/Bookmark.vue";

import TagSummary from "./newtag/TagSummary.vue";
import EditorBar from "./EditorBar.vue";
import TagEditors from "./TagEditors.vue";
import Editor from "./Editor.vue";

export default {
  name: "Home",
  components: {
    VideoPlayer,
    TimelineTabs,
    VideoTags,
    NewTag,
    Bookmarks,
    TagFilters,
    ExportTags,
    ShareTag,
    InfoTag,
    Settings,
    NewTagFullScreen,
    Goal,
    Bookmark,
    //GoalModal,
    TagSummary,
    EditorBar,
    TagEditors,
    Editor,
  },

  data() {
    return {
      refresh: 0,
    };
  },

  props: {
    goalVisible: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState("videotag", {
      data: "data",
      tagListEnabled: "tagListEnabled",
      sidebarHidden: "sidebarHidden",
      fullScreen: "fullScreen",
      currentTime: "currentTime",
      videoBookmarks: (state) => state.data.video.bookmarks,
      goalkeeperMode: "goalkeeperMode",
      newTag: "newTag",
      editor: "editor",
    }),
    ...mapGetters("videotag", ["tags"]),

    hideSidebarText() {
      return this.sidebarHidden ? "Show sidebar" : "Hide sidebar";
    },

    modeVideoText() {
      return this.data.video && this.data.video.tactical
        ? "Wiscout"
        : "Tactical";
    },
  },

  methods: {
    toggleTagList() {
      if (this.tagListEnabled) {
        this.$store.commit("videotag/disableTagList");
      } else {
        this.$store.commit("videotag/enableTagList");
      }
    },

    toggleSidebar() {
      this.$store.commit("videotag/toggleSidebar");
    },

    enableFullScreen() {
      this.$store.commit("videotag/enableFullScreen");
    },

    switchMode() {
      this.$store.commit("videotag/toggleGoalkeeperMode");
    },

    cancelNewTag() {
      this.$store.commit("videotag/cancelNewTag");
    },

    updateTag() {
      this.$store.dispatch("videotag/saveTag");
    },

    toggleVideo() {
      this.$store.commit("videotag/toggleVideo");
    },

    /*  toggleEditor() {
      this.$store.commit("videotag/toggleEditor");
    }, */
  },

  mounted() {
    this.refresh = Math.random();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}

#sidebar {
  min-width: 300px;
  max-width: 300px;
  height: 100vh;
}

.container-fluid,
#sidebar {
  padding-top: 10px;
  padding-bottom: 10px;
  /*padding-left: 10px; */
}

.col-goal {
  display: inline-flex;

  div:last-child {
    margin: 0 !important;
  }
}
</style>
