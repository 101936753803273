<template>
  <b-modal :id="`info-tag-${tag.id}-modal`" ok-only centered title="Info tag">
    <b-list-group>
      <b-list-group-item>
        <strong>Inizio:</strong> {{ timeStart }}
      </b-list-group-item>

      <b-list-group-item>
        <strong>Fine:</strong> {{ timeEnd }}
      </b-list-group-item>

      <b-list-group-item v-if="tag.field_section">
        <strong>Sezione campo:</strong> {{ tag.field_section }}
      </b-list-group-item>

      <b-list-group-item v-if="tag.type">
        <strong>Tipo:</strong> {{ tag.type }}
      </b-list-group-item>

      <b-list-group-item v-if="tag.result">
        <strong>Risultato:</strong> {{ tag.result }}
      </b-list-group-item>

      <b-list-group-item v-if="tag.skills">
        <strong>Skill:</strong> {{ skillNames }}
      </b-list-group-item>

      <b-list-group-item v-if="tag.team">
        <strong>Squadra:</strong> {{ tag.team.name }}
      </b-list-group-item>

      <b-list-group-item v-if="tag.player">
        <strong>Giocatore:</strong> {{ tag.player.name }}
      </b-list-group-item>
      <b-list-group-item v-if="tag.description">
        <strong>Descrizione:</strong> {{ tag.description }}
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import tagMixin from "@/mixins/tags.js";

export default {
  name: "InfoTag",
  mixins: [tagMixin],
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },

  computed: {
    timeStart() {
      return this.formatDuration(this.tag.start);
    },

    timeEnd() {
      return this.formatDuration(this.tag.end);
    },
  },
};
</script>
